/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";

import TableIsEmptyIcon from "../../assets/customIcons/emptyTable/TableIsEmptyIcon";

const NoInitialResults = () => {
  return (
    <Fragment>
      <div>
        <TableIsEmptyIcon
          css={css({
            fontSize: "120px",
          })}
        />
      </div>

      <div>Table is empty</div>
      <div>
        As soon as schedules are created, you will be able to see the list here
      </div>
    </Fragment>
  );
};

export default NoInitialResults;
