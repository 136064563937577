/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Skeleton from "@mui/material/Skeleton";
import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";

const PaginationLoader = ({ ...props }) => {
  const {
    colorsFacelift: { blueGray200 },
  } = useContext(ThemeContext);

  return (
    <div
      data-testid="pagination-loader"
      {...props}
      css={css({
        span: {
          background: blueGray200,
        },

        display: "flex",
        alignItems: "center",

        "& > span ": {
          height: "36px",
          borderRadius: "6px",
        },

        "& > span:nth-of-type(3),span:nth-of-type(4),span:nth-of-type(5)": {
          height: "24px",
          borderRadius: "46px",
        },

        "& > span + span": {
          marginLeft: "8px",
        },
      })}
    >
      <Skeleton variant="rectangular" width={276} />
    </div>
  );
};

export default PaginationLoader;
