/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, Fragment } from "react";
import Grid from "@mui/material/Grid";

import Filters from "../Filters";
import FilteredValues from "../Filters/components/FilteredValues";

import TableContext from "../../context/table/tableContext";
import {
  NOT_FILTER_NAMES,
  toolbarPadding,
  useParamsFromUrlAndBrowser,
} from "../../shared";

const Toolbar = () => {
  const { hideSearch, hideFilters, tableName } = useContext(TableContext);

  const { currentParams } = useParamsFromUrlAndBrowser();

  const containsOtherKeys = Object.keys(currentParams).some(
    (key) => key !== NOT_FILTER_NAMES.Selected
  );

  const hideFiltering = hideSearch && hideFilters;

  const justifyContent = hideFiltering ? "flex-end" : "space-between";

  return (
    <Fragment>
      <Grid
        container
        justifyContent={justifyContent}
        alignItems="center"
        rowSpacing="8px"
        css={css({
          padding: toolbarPadding(tableName),
          width: `calc(100% - 520px)`,
        })}
      >
        {!hideFiltering && (
          <Grid
            item
            xs0={12}
            md={12}
            lg={12}
            css={css({
              display: "flex",
              alignItems: "center",
            })}
          >
            {!hideFilters && <Filters />}
          </Grid>
        )}
      </Grid>

      {containsOtherKeys && <FilteredValues />}
    </Fragment>
  );
};

export default Toolbar;
