import { useContext, useState, useEffect } from "react";

import ParamsContext from "../../../../context/params/paramsContext";

import {
  addNotAssigned,
  API_IS_BEING_CANCELLED,
  Filter,
  FILTER_NAMES,
  useApi,
} from "../../../../shared";

export const useFilterDropdown = (filterName: FILTER_NAMES) => {
  const { filterDropdownOptions } = useContext(ParamsContext);

  const { getData, cancelSource, isCanceled } = useApi();

  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<Filter[]>([]);

  useEffect(() => {
    setOptions(filterDropdownOptions[filterName] || []);
  }, [filterDropdownOptions, filterName]);

  useEffect(() => {
    let isSubscribed = true;

    if (isOpen && options.length === 0) {
      getFilterValues(isSubscribed);
    }

    return () => {
      isSubscribed = false;
      cancelSource.cancel(API_IS_BEING_CANCELLED);

      setLoading(false);
    };

    // eslint-disable-next-line
  }, [isOpen, options]);

  const getFilterValues = async (isSubscribed: boolean) => {
    try {
      setLoading(true);

      const { data } = await getData(`${filterName}`);

      if (isSubscribed) {
        addNotAssigned(data);
        setOptions(data);
      }
    } catch (error) {
      if (isSubscribed && !isCanceled(error)) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  return { isOpen, isLoading, options, setOpen };
};
