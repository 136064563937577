import { useContext, useEffect } from "react";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
  API_IS_BEING_CANCELLED,
  getStringifiedParams,
  useApi,
  useIsMounted,
  useParamsFromUrlAndBrowser,
} from "../../../shared";

export const useTableData = () => {
  const { queryParams } = useContext(ParamsContext);

  const {
    tableName,
    sortFromNewest,
    isTableLoading,
    setDetailsData,
    setTableData,
    setTableLoading,
  } = useContext(TableContext);

  const isMounted = useIsMounted();
  const { cancelSource, isCanceled, queryData } = useApi();
  const { currentParams } = useParamsFromUrlAndBrowser();

  const checkIfVehicleIsSelected = (data: any) => {
    const foundId = data.find(
      (entry: any) => entry?.id === Number(currentParams?.selected)
    );

    return foundId ?? data[0];
  };
  const stringifiedCurrentParams = getStringifiedParams(currentParams);

  const getDataOfAllRows = async () => {
    !isTableLoading && setTableLoading(true);
    try {
      const hasSort =
        !Object.keys(queryParams).includes("sort") && sortFromNewest;

      const response = await queryData(
        tableName,
        stringifiedCurrentParams,
        hasSort
      );

      if (isMounted) {
        const {
          data: { currentPage, models, perPage, totalModels, fuelType },
        } = response;

        const payload = {
          rows: models,
          currentPage: currentPage,
          rowsPerPage: perPage,
          totalRows: totalModels,
          fuelType,
        };
        sessionStorage.clear();
        setTableData(payload);

        setDetailsData(checkIfVehicleIsSelected(models));
      }
    } catch (error) {
      if (isMounted && !isCanceled(error)) {
        console.log(error);
        const payload = {
          rows: [],
          currentPage: 1,
          rowsPerPage: 0,
          totalRows: 0,
          fuelType: {
            "0": "Any",
            "1": "Ice",
            "2": "EV",
          },
        };
        setTableData(payload);
        setDetailsData(undefined);
      }
    }
  };

  // Get main table data
  useEffect(() => {
    getDataOfAllRows();
    return () => {
      cancelSource.cancel(API_IS_BEING_CANCELLED);
    };

    // eslint-disable-next-line
  }, [stringifiedCurrentParams]);
};
