/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import TableMui from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableHead from "./TableHead";

import TableItem from "../TableItem";

import ThemeContext from "../../../../context/theme/themeContext";
import TableContext from "../../../../context/table/tableContext";

const TableDesktop = () => {
  const {
    colorsFacelift: { white, blue100, blue50, textDark },
  } = useContext(ThemeContext);

  const { rows, isTableLoading } = useContext(TableContext);

  return (
    <TableMui
      stickyHeader
      css={css({
        tbody: {
          opacity: isTableLoading ? "0.4" : "1",
          transition: "opacity 300ms ease-out",
        },

        "th, td": {
          color: textDark,
          fontSize: "14px",
          fontFamily: `Open Sans`,
          fontWeight: "600",
          lineHeight: "20px",
          letterSpacing: "0.1px",
          whiteSpace: "nowrap",
        },

        th: {
          background: blue50,
          padding: "10px 12px",
          borderTop: `1px solid ${blue100}`,
          borderBottom: `1px solid ${blue100}`,
        },

        "th:first-of-type": {
          padding: "8px 6px 8px 24px",
        },

        "th:last-of-type": {
          padding: "8px 24px 8px 12px",
        },

        td: {
          background: white,
          padding: "10px 12px",
          maxWidth: "200px",
          overflowX: "hidden",
          textOverflow: "ellipsis",
          borderBottom: "none",
        },

        "td:first-of-type": {
          padding: "10px 12px 10px 24px",
        },

        "td:last-of-type": {
          padding: "10px 24px 10px 12px",
        },

        "tbody tr": {
          "&.Mui-selected td": {
            background: blue50,
          },

          "&:hover": {
            "&.Mui-selected td": {
              background: blue100,
            },
          },
        },
      })}
    >
      <TableHead />

      <TableBody>
        {rows.map((row: any) => (
          <TableItem key={row.id} data={row} />
        ))}
      </TableBody>
    </TableMui>
  );
};

export default TableDesktop;
