import { useReducer, ReactNode, useMemo } from "react";
import ParamsContext from "./paramsContext";
import ParamsReducer from "./paramsReducer";
import {
  SET_RESOURCE,
  RESET_ALL_PARAMS,
  SET_QUERY_PARAMS,
  SET_FILTERED_VALUES,
  RELOAD_ITEMS,
} from "./paramsActions";
import { distributeFilteredValues } from "./functions";

import {
  QueryParams,
  FilteredValue,
  TABLE_NAMES,
  FilterDropdownOptions,
  NOT_FILTER_NAMES,
} from "../../shared";

interface ParamsStateProps {
  children: ReactNode;
}

const ParamsState = ({ children }: ParamsStateProps) => {
  const initialState = {
    resource: null,
    filteredItemsCount: 0,
    queryParams: {},
    filterDropdownOptions: {},
    filterQueryParams: {},
    filteredValues: [],
    stringParams: "",
    tableRowDeselectionToggle: false,
    reloadItems: {
      dashboard: false,
      devices: false,
      batches: false,
      tasks: false,
      groups: false,
      files: false,
      companies: false,
      users: false,
      models: false,
    },
  };

  const [state, dispatch] = useReducer(ParamsReducer, initialState);

  const setResource = (resource: TABLE_NAMES) =>
    dispatch({ type: SET_RESOURCE, payload: resource });

  const setQueryParams = (
    params: QueryParams,
    resetPage?: boolean,
    filteredValues?: FilteredValue,
    resetParams?: boolean,
    removeFilterOptions?: boolean,
    removeSelected?: boolean
  ) => {
    const updatedParams = {
      ...params,
      ...(Boolean(resetPage) && {
        [NOT_FILTER_NAMES.Page]: "",
        [NOT_FILTER_NAMES.Selected]: "",
      }),
      ...(Boolean(removeSelected) && {
        [NOT_FILTER_NAMES.Selected]: "",
      }),
    };

    dispatch({
      type: SET_QUERY_PARAMS,
      payload: {
        resetParams: Boolean(resetParams),
        removeFilterOptions: Boolean(removeFilterOptions),
        updatedParams,
        ...distributeFilteredValues(filteredValues),
      },
    });
  };

  const setFilteredValues = (
    data: FilteredValue[],
    filterDropdownOptions?: FilterDropdownOptions
  ) =>
    dispatch({
      type: SET_FILTERED_VALUES,
      payload: {
        data,
        filterDropdownOptions,
      },
    });

  const resetAllParams = () => dispatch({ type: RESET_ALL_PARAMS });

  const setReloadItems = (resource: TABLE_NAMES | TABLE_NAMES[]) =>
    dispatch({ type: RELOAD_ITEMS, payload: resource });

  const value = useMemo(
    () => ({
      resource: state.resource,
      filteredItemsCount: state.filteredItemsCount,
      queryParams: state.queryParams,
      filterDropdownOptions: state.filterDropdownOptions,
      filterQueryParams: state.filterQueryParams,
      filteredValues: state.filteredValues,
      stringParams: state.stringParams,
      tableRowDeselectionToggle: state.tableRowDeselectionToggle,
      reloadItems: state.reloadItems,
      setResource,
      resetAllParams,
      setQueryParams,
      setFilteredValues,
      setReloadItems,
    }),
    [
      state.resource,
      state.filteredItemsCount,
      state.queryParams,
      state.filterDropdownOptions,
      state.filterQueryParams,
      state.filteredValues,
      state.stringParams,
      state.tableRowDeselectionToggle,
      state.reloadItems,
    ]
  );

  return (
    <ParamsContext.Provider value={value}>{children}</ParamsContext.Provider>
  );
};

export default ParamsState;
