import { useState, useEffect } from "react";

import {
  NOT_FILTER_NAMES,
  ORDERS,
  QueryParams,
  SORT_RESOURCES,
} from "../../../shared";

interface Sorting {
  order: ORDERS;
  sort: SORT_RESOURCES | "";
}

export const useSorting = (
  queryParams: QueryParams,
  setParams: (data: QueryParams) => void
) => {
  const [sorting, setSorting] = useState<Sorting>({
    [NOT_FILTER_NAMES.Order]: ORDERS.Descending,
    [NOT_FILTER_NAMES.Sort]: "",
  });

  const { order, sort } = sorting;

  useEffect(() => {
    const sortingKeys = Object.keys(queryParams).filter(
      (queryKey) =>
        queryKey === NOT_FILTER_NAMES.Order ||
        queryKey === NOT_FILTER_NAMES.Sort
    );

    if (sortingKeys.length > 0) {
      const sortingParams = sortingKeys.reduce((previous, current) => {
        const singleObj = {
          [current]:
            queryParams[
              current as NOT_FILTER_NAMES.Order | NOT_FILTER_NAMES.Sort
            ],
        };

        return { ...previous, ...singleObj };
      }, sorting);

      setSorting(sortingParams);
    } else {
      setSorting({
        [NOT_FILTER_NAMES.Order]: ORDERS.Descending,
        [NOT_FILTER_NAMES.Sort]: "",
      });
    }

    // eslint-disable-next-line
  }, [queryParams]);

  const sortColumn = (resource: SORT_RESOURCES) => {
    const isDesc = order === ORDERS.Descending;

    const getOrder = () => {
      if (sort === resource) {
        return isDesc ? ORDERS.Ascending : ORDERS.Descending;
      }

      return ORDERS.Descending;
    };

    const getSort = () => {
      if (!isDesc && sort === resource) {
        return "";
      }

      return resource;
    };

    const newOrder = getOrder();
    const newSort = getSort();

    newSort === ""
      ? setParams({
          [NOT_FILTER_NAMES.Sort]: "",
          [NOT_FILTER_NAMES.Order]: "",
        })
      : setParams({
          [NOT_FILTER_NAMES.Sort]: newSort,
          [NOT_FILTER_NAMES.Order]: newOrder,
        });
  };

  return { order, sort, sortColumn };
};
