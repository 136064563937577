import { useReducer, useEffect, useContext, useMemo, useCallback } from "react";

import { useTableFunctions } from "./hooks";
import Table from "./Table";
import PageLayout from "../PageLayout";

import ParamsContext from "../../context/params/paramsContext";
import TableContext from "../../context/table/tableContext";
import TableReducer from "../../context/table/tableReducer";
import {
  SET_TABLE_DATA,
  SET_TABLE_LOADING,
  DESELECT_ALL_ROWS,
  SET_DETAILS_DATA,
} from "../../context/table/tableActions";

import {
  FUEL_TYPES,
  ID_TYPE,
  InitialColumn,
  NOT_FILTER_NAMES,
  TABLE_NAMES,
} from "../../shared";

interface TableStateProps {
  name: TABLE_NAMES;
  idType: ID_TYPE;
  isNotSelectable?: boolean;
  hideSearch?: boolean;
  hideFilters?: boolean;
  customResource?: string;
  sortFromNewest?: boolean;
  initialColumns?: InitialColumn[];
  hasDetails?: boolean;
}

const TableState = ({
  name,
  idType,
  isNotSelectable,
  hideSearch,
  hideFilters,
  customResource,
  sortFromNewest,
  initialColumns,
  hasDetails,
}: TableStateProps) => {
  const { tableRowDeselectionToggle, setQueryParams } =
    useContext(ParamsContext);

  const { renderColumnsWithIsActive } = useTableFunctions();

  const initialState = {
    tableName: name,
    idType,
    isNotSelectable: Boolean(isNotSelectable),
    hideSearch: Boolean(hideSearch),
    hideFilters: Boolean(hideFilters),
    customResource: customResource ?? "",
    sortFromNewest: Boolean(sortFromNewest),
    hasDetails: Boolean(hasDetails),
    columns: initialColumns
      ? renderColumnsWithIsActive(name, initialColumns)
      : [],
    rows: [],
    selectedRows: [],
    detailsData: null,
    currentPage: 1,
    rowsPerPage: 25,
    totalRows: 0,
    isInitialLoading: true,
    isTableLoading: true,
    fuelType: FUEL_TYPES,
  };

  const [state, dispatch] = useReducer(TableReducer, initialState);

  useEffect(() => {
    deselectAllRows();
  }, [tableRowDeselectionToggle]);

  const setTableData = useCallback((data: any) => {
    dispatch({
      type: SET_TABLE_DATA,
      payload: {
        ...data,
      },
    });
  }, []);

  const setTableLoading = (value: boolean) =>
    dispatch({ type: SET_TABLE_LOADING, payload: value });

  const deselectAllRows = () => dispatch({ type: DESELECT_ALL_ROWS });

  const setDetailsData = useCallback(
    (detailsData: any) => {
      setQueryParams({
        [NOT_FILTER_NAMES.Selected]: detailsData
          ? String(detailsData[state.idType])
          : "",
      });

      dispatch({
        type: SET_DETAILS_DATA,
        payload: { detailsData },
      });
    },
    [state.idType, setQueryParams]
  );

  const value = useMemo(
    () => ({
      tableName: state.tableName,
      idType: state.idType,
      isNotSelectable: state.isNotSelectable,
      hideSearch: state.hideSearch,
      hideFilters: state.hideFilters,
      customResource: state.customResource,
      sortFromNewest: state.sortFromNewest,
      hasDetails: state.hasDetails,
      columns: state.columns,
      rows: state.rows,
      selectedRows: state.selectedRows,
      detailsData: state.detailsData,
      currentPage: state.currentPage,
      rowsPerPage: state.rowsPerPage,
      totalRows: state.totalRows,
      isInitialLoading: state.isInitialLoading,
      isTableLoading: state.isTableLoading,
      fuelType: state.fuelType,
      setTableData,
      setTableLoading,
      deselectAllRows,
      setDetailsData,
    }),
    [
      state.tableName,
      state.idType,
      state.isNotSelectable,
      state.hideSearch,
      state.hideFilters,
      state.customResource,
      state.sortFromNewest,
      state.hasDetails,
      state.columns,
      state.rows,
      state.selectedRows,
      state.detailsData,
      state.currentPage,
      state.rowsPerPage,
      state.totalRows,
      state.isInitialLoading,
      state.isTableLoading,
      state.fuelType,
      setTableData,
      setDetailsData,
    ]
  );

  return (
    <TableContext.Provider value={value}>
      <PageLayout />
      <Table />
    </TableContext.Provider>
  );
};

export default TableState;
