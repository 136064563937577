import { Fragment } from "react";
import MainContainer from "../MainContainer";
import Table from "../Table";
import { ID_TYPE, TABLE_NAMES, VEHICLE_COLUMNS as COLUMNS } from "../../shared";

const Vehicles = () => {
  return (
    <Fragment>
      <MainContainer>
        <Table
          name={TABLE_NAMES.Models}
          initialColumns={COLUMNS}
          idType={ID_TYPE.Id}
          isNotSelectable
          hideSearch
        />
      </MainContainer>
    </Fragment>
  );
};
export default Vehicles;
