/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import RectangleLoader from "./RectangleLoader";

import ThemeContext from "../../context/theme/themeContext";

interface IActionBarTitleLoaderProps {
  customStyle?: any;
}

const ActionBarTitleLoader = ({ customStyle }: IActionBarTitleLoaderProps) => {
  const {
    colorsFacelift: { blueGray200 },
  } = useContext(ThemeContext);

  return (
    <div
      css={[
        css({
          display: "flex",
          alignItems: "center",

          "span + span": {
            marginLeft: "8px",
          },
        }),
        customStyle,
      ]}
    >
      <RectangleLoader
        width={255}
        borderRadius={"46px"}
        backgroundColor={blueGray200}
        height={24}
      />
    </div>
  );
};

export default ActionBarTitleLoader;
