export enum COLORS {
  Black = "#000000",
  Blue50 = "#EAF5FF",
  Blue100 = "#C5E2FF",
  Blue200 = "#A0D0FF",
  Blue300 = "#70B8FF",
  Blue700 = "#0054A6",
  Blue800 = "#004385",
  Blue900 = "#00386F",
  BlueGray50 = "#F8FAFC",
  BlueGray100 = "#F1F5F9",
  BlueGray200 = "#E2E8F0",
  BlueGray300 = "#CBD5E1",
  BlueGray400 = "#94A3B8",
  BlueGray500 = "#64748B",
  BlueGray600 = "#475569",
  BlueGray700 = "#334155",
  BlueSideNavigation = "#0B265A",
  Cyan300 = "#67E8F9",
  Cyan600 = "#0891B2",
  Green50 = "#EDFDF3",
  Green100 = "#C9F8DA",
  Green200 = "#A5F3C2",
  Green300 = "#6FEB9D",
  Green400 = "#4ADE80",
  Green500 = "#22C55E",
  Green600 = "#16A34A",
  Green700 = "#15803D",
  Green800 = "#166534",
  Green900 = "#14532D",
  Grey80 = "#263238",
  InkNormal = "#252A31",
  Orange50 = "#FFF7ED",
  Orange100 = "#FFEDD5",
  Orange200 = "#FED7AA",
  Orange300 = "#FDBA74",
  Orange400 = "#FB923C",
  Orange500 = "#F97316",
  Orange600 = "#EA580C",
  Orange700 = "#C2410C",
  Purple50 = "#F4F0FA",
  Purple100 = "#DDD1F0",
  Purple700 = "#5A319B",
  Purple900 = "#2D184E",
  Red50 = "#FEF2F2",
  Red100 = "#FEE2E2",
  Red200 = "#FECACA",
  Red300 = "#FCA5A5",
  Red600 = "#DC2626",
  Red700 = "#B91C1C",
  Red800 = "#991B1B",
  Red900 = "#7F1D1D",
  TextDark = "#0F172A",
  TextDarkDisabled = "#A6B6C8",
  TextDarkSecondary = "#334155",
  TextIconDark = "#162D3D",
  TextPlaceholder = "#697D95",
  TextSuccess = "#14532D",
  TextWarning = "#7C2D12",
  TextWhite = "#FFFFFF",
  White = "#FFFFFF",
}
