/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import VehiclesBar from "./VehiclesBar";

import ThemeContext from "../../../context/theme/themeContext";

const ActionBar = () => {
  const {
    colorsFacelift: { blueGray200, blueGray50, textDark, textPlaceholder },
  } = useContext(ThemeContext);

  return (
    <div
      css={css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: blueGray50,
        borderBottom: `1px solid ${blueGray200}`,
        padding: "22px 24px",
        width: `calc(100% - 568px)`,
        h1: {
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "24px",
          letterSpacing: "0.15px",

          "& > span:first-of-type": {
            color: textDark,
            marginRight: "5px",
          },

          "& > span:last-of-type": {
            color: textPlaceholder,
          },
        },
      })}
    >
      <VehiclesBar />
    </div>
  );
};

export default ActionBar;
