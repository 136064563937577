/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import TableRowMui from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { useTableFunctions } from "../../hooks";

import ThemeContext from "../../../../context/theme/themeContext";

import TableContext from "../../../../context/table/tableContext";
import { Column, noop } from "../../../../shared";

interface TableRowProps {
  data: any;
}

const TableRow = ({ data }: TableRowProps) => {
  const {
    colorsFacelift: { white, blueGray200, blueGray50 },
  } = useContext(ThemeContext);

  const {
    isNotSelectable,
    isTableLoading,
    idType,
    columns,
    detailsData,
    setDetailsData,
  } = useContext(TableContext);

  const { renderValue } = useTableFunctions();

  const paddingLeft = (isSticky: boolean | undefined) => {
    if (isSticky) {
      return isNotSelectable ? "0" : "60px";
    }

    return "unset";
  };

  const handleClick = () => {
    setDetailsData(data);
    noop();
  };

  return (
    <TableRowMui
      onClick={handleClick}
      selected={detailsData && data[idType] === detailsData[idType]}
      css={css({
        cursor: isTableLoading ? "auto" : "pointer",

        td: {
          background: white,
          animationDuration: "0.5s",
          animationTimingFunction: "ease-out",
        },

        "&:hover td": {
          background: blueGray50,
        },
      })}
    >
      {/* Column values */}
      {columns.map(
        ({ name, resource, isActive, isSticky, isTranslatable }: Column) =>
          isActive && (
            <TableCell
              key={name}
              css={css([
                {
                  position: isSticky ? "sticky" : "unset",
                  left: paddingLeft(isSticky),
                  boxShadow: `inset 0px -1px 0px ${blueGray200}`,
                },
              ])}
            >
              {renderValue(name, resource, data, isTranslatable)}
            </TableCell>
          )
      )}
    </TableRowMui>
  );
};

export default TableRow;
