import TableRow from "./TableRow";

interface TableItemProps {
  data: any;
}

const TableItem = ({ data }: TableItemProps) => {
  return <TableRow data={data} />;
};

export default TableItem;
