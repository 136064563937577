import { useContext } from "react";

import Autocomplete from "../../DesignComponents/Autocomplete";

import RectangleLoader from "../../../shared/loaders/RectangleLoader";
import ParamsContext from "../../../context/params/paramsContext";
import ThemeContext from "../../../context/theme/themeContext";
import { DROPDOWN_SIZES } from "../../../shared";

interface ITableViewProps {
  isInitialLoading: boolean;
  totalRows: number;
  rowsPerPage: number;
}

type Option = "25" | "50" | "100";

const TableView = ({
  isInitialLoading,
  totalRows,
  rowsPerPage,
}: ITableViewProps) => {
  const {
    colorsFacelift: { blueGray200 },
  } = useContext(ThemeContext);

  const { setQueryParams } = useContext(ParamsContext);

  const options: Option[] = totalRows > 50 ? ["25", "50", "100"] : ["25", "50"];

  const selectedValue = options.includes(String(rowsPerPage) as Option)
    ? rowsPerPage
    : options[0];

  const handleChange = (value: Option) => {
    const payload = { perPage: value, pageNum: "1" };

    setQueryParams(payload);
  };

  return !isInitialLoading ? (
    <Autocomplete
      disableClearable
      size={DROPDOWN_SIZES.Small}
      customStyle={{
        flexDirection: "row",
        gap: "8px",
      }}
      labelLeft="Rows per page"
      options={options}
      textFieldParams={{
        placeholder: String(rowsPerPage),
      }}
      value={(selectedValue && String(selectedValue)) || "25"}
      onChange={(_, value) => handleChange(value)}
    />
  ) : (
    <RectangleLoader
      customStyle={{
        backgroundColor: blueGray200,
        margin: "0 24px",
      }}
      width="200px"
      height="36px"
    />
  );
};

export default TableView;
