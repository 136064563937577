/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { useTableFunctions } from "../../hooks";
import TableView from "../../../Toolbar/TableView";
import Pagination from "../../../DesignComponents/Pagination";
import ThemeContext from "../../../../context/theme/themeContext";
import TableContext from "../../../../context/table/tableContext";

const TableFooter = () => {
  const { currentPage, rowsPerPage, totalRows, isInitialLoading } =
    useContext(TableContext);

  const {
    colorsFacelift: { blueGray100, blueGray200 },
  } = useContext(ThemeContext);

  const { handlePageChange } = useTableFunctions();

  return (
    <div
      data-testid="table-footer"
      css={css({
        display: "flex",
        alignItems: "center",
        position: "relative",
        bottom: "0",
        width: `100%`,

        height: "52px",
        backgroundColor: blueGray100,
        boxShadow: `0px 1px 0px 0px ${blueGray200} inset`,
        justifyContent: "space-between",
        flexDirection: "row",
        "& > div": {
          padding: "0 24px",
        },
        "& > div:first-of-type": {
          margin: "unset",
        },
      })}
    >
      <TableView
        isInitialLoading={isInitialLoading}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
      />

      <Pagination
        page={currentPage}
        total={totalRows}
        perPage={rowsPerPage}
        onChange={handlePageChange}
        isInitialLoading={isInitialLoading}
      />
    </div>
  );
};

export default TableFooter;
