import { useMemo } from "react";
import ThemeContext from "./themeContext";
import { ALL_COLORS } from "./constants";
import { ThemeStateProps } from "./types";

const ThemeState = ({ children }: ThemeStateProps) => {
  const state = {
    colors: ALL_COLORS,
  };
  const value = useMemo(
    () => ({ colorsFacelift: state.colors }),
    [state.colors]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeState;
