import { useContext, useState, useEffect } from "react";

import {
  convertEnumToObjectArray,
  filteredItems,
  findActualFilters,
  getFilteredCheckboxValues,
  getFilteredListFieldNames,
  getModifiedFilterName,
  getStartYearOptions,
  transformedFilteredItems,
  updateFilterValues,
  updateQueryValue,
} from "../functions";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";

import {
  addNotAssigned,
  Filter,
  FilterDropdownOptions,
  FilteredValue,
  FILTER_NAMES,
  useApi,
  API_IS_BEING_CANCELLED,
  FILTER_LIST_FIELD_NAMES,
  useParamsFromUrlAndBrowser,
  ENDPOINTS,
  CUSTOM_FILTERS,
  isFilter,
} from "../../../shared";
import { useDecoder } from "../../../shared/hooks/useDecoder";
import { AxiosResponse } from "axios";

export const useFilteredValues = () => {
  const {
    filterQueryParams,
    filteredValues: mainFilteredValues,
    setFilteredValues,
    setQueryParams,
    resetAllParams,
  } = useContext(ParamsContext);

  const { tableName, fuelType, deselectAllRows } = useContext(TableContext);

  const { currentParams } = useParamsFromUrlAndBrowser();
  const { getData, cancelSource, isCanceled } = useApi();
  const { getDecodedParams, decodeArray } = useDecoder();

  const updatedParams = getDecodedParams(currentParams);

  const filterNames = Object.keys(updatedParams).filter((filterName) =>
    isFilter(filterName)
  ) as FILTER_NAMES[];

  const filtersExist = filterNames.length > 0;

  const [isLoading, setLoading] = useState(filtersExist);

  const filteredValues = mainFilteredValues;

  const filteredListFieldNames = getFilteredListFieldNames(
    filterNames,
    FILTER_LIST_FIELD_NAMES,
    tableName
  );

  const addResults = (
    result: AxiosResponse<any, any>,
    filteredValue: FilteredValue[],
    filterOptions: FilterDropdownOptions,
    customFilterName?: FILTER_NAMES | CUSTOM_FILTERS
  ) => {
    const { config, data: options }: { config: any; data: Filter[] } = result;

    const filterName: FILTER_NAMES = customFilterName ?? config.url;

    const modifiedFilterName: FILTER_NAMES = getModifiedFilterName(filterName);
    const filterValue = updatedParams[modifiedFilterName];

    const customFilterOptions = (() => {
      switch (customFilterName) {
        case CUSTOM_FILTERS.ExtendedFilter:
          return result.data.codes;
        case CUSTOM_FILTERS.ProductionYear:
          return getStartYearOptions(result.data.startYear);
        case CUSTOM_FILTERS.FuelType:
          return convertEnumToObjectArray(fuelType);
        default:
          return options;
      }
    })();

    addNotAssigned(customFilterOptions);

    filteredValue.push({
      filterName,
      value: transformedFilteredItems(
        filteredItems(customFilterOptions, filterValue, modifiedFilterName)
      ),
    });

    filterOptions[filterName] = customFilterOptions;
  };

  const getValueAndOptions = async () => {
    const endpoints = filteredListFieldNames.map((filterName) => {
      const endpointName: FILTER_NAMES = getModifiedFilterName(filterName);
      return getData(`${endpointName}`);
    });

    const results = await Promise.all(endpoints);

    const filteredValue: FilteredValue[] = [];
    const filterOptions: FilterDropdownOptions = {};

    results.forEach((result) => {
      addResults(result, filteredValue, filterOptions);
    });

    const filterNamesToCheck: (FILTER_NAMES | CUSTOM_FILTERS)[] =
      Object.values(CUSTOM_FILTERS);

    const isCodesEndpointNeeded = filterNamesToCheck.some((filterNameToCheck) =>
      filterNames.includes(filterNameToCheck as FILTER_NAMES)
    );

    if (isCodesEndpointNeeded) {
      const codesEndpoint = await getData(ENDPOINTS.Codes);

      filterNamesToCheck.forEach((filterNameToCheck) => {
        if (filterNames.includes(filterNameToCheck as FILTER_NAMES)) {
          addResults(
            codesEndpoint,
            filteredValue,
            filterOptions,
            filterNameToCheck
          );
        }
      });
    }

    return { filteredValue, filterOptions };
  };

  useEffect(() => {
    let isSubscribed = true;

    const getFilteredValues = async () => {
      try {
        setLoading(true);

        if (isSubscribed) {
          const { filteredValue: value, filterOptions: allFilterOptions } =
            await getValueAndOptions();

          const checkboxFilteredValues = getFilteredCheckboxValues(filterNames);

          setFilteredValues(
            [...value, ...checkboxFilteredValues],
            allFilterOptions
          );
        }
      } catch (error) {
        if (isSubscribed && !isCanceled(error)) {
          console.log(error);
        }
      }

      setLoading(false);
    };

    if (filtersExist) {
      getFilteredValues();
    }

    return () => {
      isSubscribed = false;
      cancelSource.cancel(API_IS_BEING_CANCELLED);

      setFilteredValues([]);
      setLoading(false);
    };

    // eslint-disable-next-line
  }, []);

  const handleDelete = (
    filterName: FILTER_NAMES,
    id: number | "null",
    name: string
  ) => {
    const customFilterName: FILTER_NAMES = getModifiedFilterName(filterName);

    const currentQueryValues = filterQueryParams[customFilterName] as
      | string
      | string[];

    const decodedQueryValues = Array.isArray(currentQueryValues)
      ? decodeArray(currentQueryValues)
      : currentQueryValues;

    const isModelFilter = filterName === FILTER_NAMES.Model ? name : null;

    const updatedQueryValue = updateQueryValue(
      decodedQueryValues,
      id,
      isModelFilter
    );

    const actualFilter = findActualFilters(filteredValues, filterName);

    const updatedFilteredValues = updateFilterValues(
      actualFilter,
      id,
      isModelFilter
    );

    const paramsPayload = {
      [customFilterName]: updatedQueryValue,
    };

    const filteredValuesPayload = {
      filterName,
      value: updatedFilteredValues,
    };

    setQueryParams(paramsPayload, true, filteredValuesPayload);

    deselectAllRows();
  };

  const handleClearAll = () => {
    resetAllParams();
    deselectAllRows();
  };

  return { isLoading, filteredValues, handleDelete, handleClearAll };
};
