/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, Fragment } from "react";
import Skeleton from "@mui/material/Skeleton";

import ThemeContext from "../../context/theme/themeContext";
import { useMediaQueries } from "../../shared";

interface TableLoaderProps {
  hideHeader?: boolean;
}

const TableLoader = ({ hideHeader }: TableLoaderProps) => {
  const {
    colorsFacelift: { white, blueGray200, blue100, blue50 },
  } = useContext(ThemeContext);

  const { toMd } = useMediaQueries();

  const [rowsCount, setRowsCount] = useState(0);

  const skeletonHeightWhenHeaderIsHidden = toMd ? 56 : 44;

  return (
    <div
      className="remaining-height"
      css={css({
        overflow: "hidden",
      })}
      ref={(node: HTMLDivElement) => {
        if (node) {
          const height = node.clientHeight;

          if (height !== 0) {
            const rows = Math.floor(height / 44) - 1;
            rows >= 0 && setRowsCount(rows);
          }
        }
      }}
    >
      <div
        css={css({
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
        })}
      >
        {hideHeader ? (
          <Skeleton
            variant="rectangular"
            height={skeletonHeightWhenHeaderIsHidden}
            css={css({
              background: white,
              borderTop: `1px solid ${blueGray200}`,
              borderBottom: `1px solid ${blueGray200}`,
            })}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={42}
            css={css({
              position: "sticky",
              top: "0",
              zIndex: "1",
              boxSizing: "border-box",
              background: blue50,
              borderTop: `1px solid ${blue100}`,
              borderBottom: `1px solid ${blue100}`,
            })}
          />
        )}

        <div
          css={css({
            flex: "1",
          })}
        >
          {Array(rowsCount)
            .fill(null)
            .map((_, i) => {
              const rowId = `TableLoaderRow_${i}`;

              return (
                <Fragment key={rowId}>
                  <Skeleton
                    variant="rectangular"
                    height={toMd ? 55 : 43}
                    css={css({
                      background: white,
                      borderBottom: `1px solid ${blueGray200}`,
                    })}
                  />
                </Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TableLoader;
