import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../DesignComponents/BannerMessage";
import {
  TOOLTIP_PLACEMENT,
  TOOLTIP_SIZES,
  TooltipGeneral,
} from "../../../DesignComponents/Tooltips";

const ExtendedIcon = () => {
  return (
    <TooltipGeneral
      title="Available in Extended package"
      placement={TOOLTIP_PLACEMENT.Top}
      size={TOOLTIP_SIZES.Medium}
    >
      <span>
        <BannerMessage
          status={BANNER_MESSAGE_STATUSES.Info}
          title={"Extended"}
        />
      </span>
    </TooltipGeneral>
  );
};

export default ExtendedIcon;
