import { useContext, Children, cloneElement } from "react";
import MuiTabs from "@mui/material/Tabs";

import { CombinedTabsProps, TAB_SIZES } from "./types";
import ThemeContext from "../../../context/theme/themeContext";

const Tabs = ({ children, size, ...props }: CombinedTabsProps) => {
  const {
    colorsFacelift: {
      blue700,
      blueGray700,
      blueGray300,
      blueGray100,
      blueGray50,
      textDark,
      textPlaceholder,
      white,
    },
  } = useContext(ThemeContext);

  const isSmall = size === TAB_SIZES.Small;

  return (
    <MuiTabs
      {...props}
      sx={{
        minHeight: "unset",

        "& .MuiTabs-scrollButtons": {
          color: blueGray700,
        },

        "& .MuiTabs-indicator": {
          backgroundColor: blue700,
        },

        "& .MuiTabs-scrollButtons.Mui-disabled": {
          opacity: "1",
        },
      }}
    >
      {Children.map(children, (child) =>
        cloneElement(child, {
          sx: {
            color: textPlaceholder,
            lineHeight: "24px",
            fontWeight: "600",
            borderRadius: "9px 9px 0 0",
            background: white,
            textTransform: "none",
            minWidth: "unset",
            minHeight: "unset",
            padding: isSmall ? "8px 16px" : "12px 16px",
            fontSize: "20px",
            letterSpacing: "0.15px",
            boxShadow: `inset 0px -2px 0px ${blueGray100}`,
            transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,

            "&.Mui-selected": {
              color: textDark,
            },

            "&:hover": {
              background: blueGray50,
              boxShadow: `inset 0px -2px 0px ${blueGray300}`,
            },
          },
        })
      )}
    </MuiTabs>
  );
};

export default Tabs;
