import { useContext } from "react";

import Checkbox from "../../DesignComponents/Checkbox";
import ParamsContext from "../../../context/params/paramsContext";
import { CODE_TYPE, FILTER_NAMES } from "../../../shared";

interface FilterCheckboxProps {
  filter: FILTER_NAMES;
  title: CODE_TYPE;
}

const FilterCheckbox = ({ filter, title }: FilterCheckboxProps) => {
  const { filteredValues, setQueryParams } = useContext(ParamsContext);

  const isChecked = filteredValues.some(
    (filteredValue) => filteredValue.filterName === filter
  );

  const handleChange = (e: { target: { checked: boolean } }) => {
    const newValue = e.target.checked ? "true" : "";

    setQueryParams(
      {
        [filter]: newValue,
      },
      true,
      {
        filterName: filter,
        value: newValue ? [{ id: "null", name: filter }] : [],
      }
    );
  };

  return (
    <div>
      <Checkbox label={title} checked={isChecked} onChange={handleChange} />
    </div>
  );
};

export default FilterCheckbox;
