/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";

import { LabelProps } from "./types";
import { TOOLTIP_PLACEMENT, TooltipGeneral } from "../Tooltips";
import ThemeContext from "../../../context/theme/themeContext";
import InfoIcon from "../../../assets/customIcons/status/InfoIcon";

const Label = ({
    titleLeft,
    titleTooltip,
    titleRight,
    isRequired,
    ...props
}: LabelProps) => {
    const {
        colorsFacelift: { blue700, blueGray700 }
    } = useContext(ThemeContext);

    const getJustifyPosition = () => {
        if (titleLeft && titleRight) {
            return "space-between";
        }

        return titleLeft ? "flex-start" : "flex-end";
    };

    const justifyContent = getJustifyPosition();

    return (
        <div
            {...props}
            css={css({
                display: "flex",
                justifyContent,
                alignItems: "center",
                marginBottom: "4px",
                color: blueGray700,
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0.1px"
            })}
        >
            {titleLeft && (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center"
                    })}
                >
                    <span>{titleLeft}</span>

                    {isRequired && <Fragment>&#42;</Fragment>}

                    {titleTooltip && (
                        <TooltipGeneral
                            title={titleTooltip}
                            placement={TOOLTIP_PLACEMENT.Top}
                        >
                            <span
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "4px",

                                    "& > svg": {
                                        fontSize: "16px",
                                        color: blue700
                                    }
                                })}
                            >
                                <InfoIcon />
                            </span>
                        </TooltipGeneral>
                    )}
                </div>
            )}

            {titleRight && <div>{titleRight}</div>}
        </div>
    );
};

export default Label;
