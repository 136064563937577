import { useContext } from "react";
import { styled } from "@mui/material/styles";
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { TOOLTIP_PLACEMENT, TOOLTIP_SIZES, TooltipGeneralProps } from "./types";
import ThemeContext from "../../../context/theme/themeContext";

export const TooltipGeneral = styled(
    ({
        className,
        size = TOOLTIP_SIZES.Small,
        ...props
    }: TooltipGeneralProps) => (
        <MuiTooltip
            {...props}
            enterTouchDelay={0}
            leaveTouchDelay={60000}
            classes={{ popper: className }}
            arrow={size !== TOOLTIP_SIZES.Small}
        />
    )
)(({ size = TOOLTIP_SIZES.Small, ...props }) => {
    const {
        colorsFacelift: { blueGray700, white }
    } = useContext(ThemeContext);

    const getArrowPosition = () => {
        switch (props.placement) {
            case TOOLTIP_PLACEMENT.TopStart:
            case TOOLTIP_PLACEMENT.BottomStart:
                return {
                    transform: "unset !important",
                    left: "12px !important",
                    right: "unset !important"
                };
            case TOOLTIP_PLACEMENT.TopEnd:
            case TOOLTIP_PLACEMENT.BottomEnd:
                return {
                    transform: "unset !important",
                    left: "unset !important",
                    right: "12px !important"
                };
            case TOOLTIP_PLACEMENT.LeftStart:
            case TOOLTIP_PLACEMENT.RightStart:
                return {
                    transform: "unset !important",
                    top: "12px !important",
                    bottom: "unset !important"
                };
            case TOOLTIP_PLACEMENT.LeftEnd:
            case TOOLTIP_PLACEMENT.RightEnd:
                return {
                    transform: "unset !important",
                    top: "unset !important",
                    bottom: "12px !important"
                };
            case TOOLTIP_PLACEMENT.Left:
            case TOOLTIP_PLACEMENT.Right:
                return {
                    transform: "unset !important",
                    top: "calc(50% - 6px) !important"
                };
            default:
                return {};
        }
    };

    const arrowPosition = getArrowPosition();

    return {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: blueGray700,
            color: white,
            fontWeight: "400",
            fontSize: size === TOOLTIP_SIZES.Small ? "12px" : "14px",
            lineHeight: size === TOOLTIP_SIZES.Small ? "16px" : "20px",
            letterSpacing: size === TOOLTIP_SIZES.Small ? "0.4px" : "0.1px",
            padding: size === TOOLTIP_SIZES.Small ? "8px 16px" : "12px 24px",
            borderRadius: "8px"
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: blueGray700,
            width: "0",
            height: "0",
            ...arrowPosition
        },
        [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
            marginBottom: "4px !important"
        },
        [`&[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
            marginTop: "4px !important"
        },
        [`&[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
            marginRight: "4px !important"
        },
        [`&[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
            marginLeft: "4px !important"
        },
        [`&[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: `4px solid ${blueGray700}`,
            marginBottom: "-4px"
        },
        [`&[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom: `4px solid ${blueGray700}`,
            marginTop: "-4px"
        },
        [`&[data-popper-placement*="left"] .${tooltipClasses.arrow}`]: {
            borderTop: "6px solid transparent",
            borderBottom: "6px solid transparent",
            borderLeft: `4px solid ${blueGray700}`,
            marginRight: "-4px",
            width: "0",
            height: "0"
        },
        [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
            borderTop: "6px solid transparent",
            borderBottom: "6px solid transparent",
            borderRight: `4px solid ${blueGray700}`,
            marginLeft: "-4px",
            width: "0",
            height: "0"
        }
    };
});
