/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";

const GlobalStyles = () => {
  const {
    colorsFacelift: { blue200, blue100, white },
  } = useContext(ThemeContext);

  return (
    <Global
      styles={css({
        html: {
          height: "100%",
          background: white,
        },

        body: {
          height: "100%",
          margin: "0",
          fontFamily: '"Open Sans", sans-serif',
          overflow: "unset",
          overflowX: "hidden",

          "header + main": {
            width: "100%",
          },
        },

        "#root": {
          minHeight: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          scrollbarColor: `${blue100} transparent !important`,
        },

        // for non-chromium browsers
        div: {
          scrollbarWidth: "thin",
          scrollbarColor: `${blue100} transparent !important`,
        },

        "main, .remaining-height": {
          display: "flex",
          flexDirection: "column",
          flex: "1",
        },

        "h1, h2, h3, h4, h5, h6": {
          margin: "0",
          fontWeight: "unset",
        },

        ul: {
          margin: "0",
          padding: "0",
        },

        li: {
          listStyle: "none",
        },

        a: {
          textDecoration: "none",
          color: "inherit",
          WebkitTapHighlightColor: "transparent",
        },

        ".VehicleInfoWindow": {
          height: `calc(100vh - 270px)`,
          overflowY: "scroll",

          "@-moz-document url-prefix()": {
            paddingRight: "5px",
            marginRight: "-13px",
          },

          marginRight: "-18px",
        },

        "@media (min-width: 840px)": {
          "*": {
            "&::-webkit-scrollbar": {
              width: "18px",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: blue100,
              borderRadius: "100px",
              border: `5px solid ${white}`,
              minHeight: "60px",
            },

            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: blue200,
            },
          },
        },
      })}
    />
  );
};

export default GlobalStyles;
