/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { forwardRef, useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button as MuiButton } from "@mui/material";

import TextOnlyButton from "./TextOnlyButton";
import IconLeftButton from "./IconLeftButton";
import IconRightButton from "./IconRightButton";

import IconOnlyButton from "./IconOnlyButton";
import {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  ButtonProps,
  Ref,
  StyleOptions,
} from "./types";
import { useStyleOptions } from "./hooks/useStyleOptions";

import ThemeContext from "../../../context/theme/themeContext";
import RectangleLoader from "../../../shared/loaders/RectangleLoader";

const Button = forwardRef<Ref, ButtonProps>(
  (
    {
      children,
      variant,
      size,
      color,
      icon,
      isLoading,
      focus,
      hasRectangleLoader,
      ...props
    },
    ref
  ) => {
    const {
      colorsFacelift: { white, blueGray100, blueGray200, textDarkDisabled },
    } = useContext(ThemeContext);

    const getDisabledBgColor = () => {
      if (color === "transparent") {
        return "rgba(255, 255, 255, 0.05)";
      }

      return color === BUTTON_COLORS.White ? white : blueGray100;
    };

    const renderButtonVariant = () => {
      switch (variant) {
        case BUTTON_VARIANTS.IconLeft:
          return <IconLeftButton title={children} icon={icon} />;
        case BUTTON_VARIANTS.IconRight:
          return <IconRightButton title={children} icon={icon} />;
        case BUTTON_VARIANTS.IconOnly:
          return <IconOnlyButton icon={icon} />;
        default:
          return <TextOnlyButton title={children} />;
      }
    };

    const styleOptions: StyleOptions = useStyleOptions();

    const bgColorRest = styleOptions.colors[color].background.rest;
    const bgColorHover = styleOptions.colors[color].background.hover;
    const bgColorActive = styleOptions.colors[color].background.active;
    const bgColorDisabled = getDisabledBgColor();

    const textColor = styleOptions.colors[color].text;
    const textColorDisabled =
      color === BUTTON_COLORS.Transparent
        ? "rgba(255, 255, 255, 0.3)"
        : textDarkDisabled;

    const padding = styleOptions.paddings[variant][size];
    const fontSize = styleOptions.typography[size].fontSize;
    const lineHeight = styleOptions.typography[size].lineHeight;
    const letterSpacing = styleOptions.typography[size].letterSpacing;

    const circularProgressColor = styleOptions.colors[color].loader;
    const circularProgressSize = size === BUTTON_SIZES.Tiny ? 12 : 20;

    return (
      <>
        {hasRectangleLoader && (
          <RectangleLoader height={36} backgroundColor={blueGray200} />
        )}

        {!hasRectangleLoader && (
          <MuiButton
            {...props}
            ref={ref}
            autoFocus={focus}
            variant="contained"
            sx={{
              textTransform: "none",
              fontWeight: "600",
              borderRadius: "6px",
              boxShadow: "none",
              minWidth: "unset",
              backgroundColor: bgColorRest,
              color: textColor,
              whiteSpace: "nowrap",
              fontSize,
              lineHeight,
              letterSpacing,
              padding,
              pointerEvents: isLoading ? "none" : "auto",

              "&.Mui-focusVisible": {
                boxShadow: "none",
              },

              "&:hover": {
                boxShadow: "none",
                backgroundColor: bgColorHover,
              },

              "&:active": {
                boxShadow: "none",
                backgroundColor: bgColorActive,
              },

              "&.Mui-disabled": {
                backgroundColor: bgColorDisabled,
                color: textColorDisabled,
              },

              "& .MuiSvgIcon-root": {
                fontSize: size === BUTTON_SIZES.Tiny ? "16px" : "24px",
              },
            }}
          >
            <div
              css={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                visibility: isLoading ? "hidden" : "visible",
              })}
            >
              {renderButtonVariant()}
            </div>

            {isLoading && (
              <CircularProgress
                size={circularProgressSize}
                sx={{
                  color: circularProgressColor,
                  position: "absolute",
                  left: `calc(50% - ${circularProgressSize / 2}px)`,
                }}
              />
            )}
          </MuiButton>
        )}
      </>
    );
  }
);

export { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from "./types/";

export default Button;
