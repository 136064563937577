import {
  COLUMN_NAMES,
  COLUMN_RESOURCES,
  InitialColumn,
  SORT_RESOURCES,
} from "..";

export const VEHICLE_COLUMNS: InitialColumn[] = [
  {
    name: COLUMN_NAMES.Info,
    resource: COLUMN_RESOURCES.InfoIcons,
    isBadge: true,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.Make,
    resource: COLUMN_RESOURCES.Make,
    sortResource: SORT_RESOURCES.Make,
    isDefaultActive: true,
    isDisabled: true,
    isSticky: true,
  },
  {
    name: COLUMN_NAMES.Model,
    resource: COLUMN_RESOURCES.Model,
    sortResource: SORT_RESOURCES.Model,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.ProductionYear,
    resource: COLUMN_RESOURCES.ProductionYear,
    sortResource: SORT_RESOURCES.ProductionYear,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.FuelType,
    resource: COLUMN_RESOURCES.FuelType,
    sortResource: SORT_RESOURCES.FuelType,
    isDefaultActive: true,
  },
];
