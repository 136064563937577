import { useUrlQueryParams } from ".";

export const useParamsFromUrlAndBrowser = () => {
  const { getUrlParams } = useUrlQueryParams();

  const queryParamsFromUrl = getUrlParams();

  const currentParams = {
    ...queryParamsFromUrl,
  };

  return {
    queryParamsFromUrl,
    currentParams,
  };
};
