/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import TableContext from "../../../context/table/tableContext";
import { useFormattedNumber } from "../../../shared";
import ActionBarTitleLoader from "../../../shared/loaders/ActionBarTitleLoader";

const VehiclesBar = () => {
  const { totalRows, rows, isTableLoading } = useContext(TableContext);

  const { renderFormattedNumber } = useFormattedNumber();

  return (
    <div>
      {isTableLoading && rows.length === 0 ? (
        <ActionBarTitleLoader />
      ) : (
        <div
          css={css({
            display: "flex",
            alignItems: "center",

            "& > button": {
              marginLeft: "16px",
            },
          })}
        >
          <h1>
            <span>Supported Vehicles</span>
            <span>({renderFormattedNumber(totalRows)})</span>
          </h1>
        </div>
      )}
    </div>
  );
};

export default VehiclesBar;
