import { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { ROUTES } from "./types/enums";
import Navbar from "../Navbar";
import Vehicles from "../Vehicles";

const RouterContent = () => {
  return (
    <Fragment>
      <Navbar />

      <Routes>
        <Route path={ROUTES.OBD} Component={Vehicles} />
        <Route path="/" element={<Navigate to={ROUTES.OBD} />} />
      </Routes>
    </Fragment>
  );
};

export default RouterContent;
