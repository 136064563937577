export enum TABLE_NAMES {
  Models = "models",
}

export enum TABLE_NAME_RESOURCES_FOR_FILTERS {
  Models = "models",
}

export enum COLUMN_NAMES {
  Info = "info",
  Field = "field",
  Make = "Make",
  Model = "Model",
  ProductionYear = "Production year",
  FuelType = "Fuel Type",
  TotalMileage = "total mileage",
  FuelLevelLitres = "fuel level (L)",
  FuelLevelPercent = "fuel level (%)",
  Doors = "doors",
}

export enum COLUMN_RESOURCES {
  InfoIcons = "infoIcons",
  Make = "manufacturer",
  Model = "name",
  ProductionYear = "startYear",
  FuelType = "fuelType",
}

export enum SORT_RESOURCES {
  Model = "name",
  Make = "make",
  FuelType = "fuelType",
  ProductionYear = "startYear",
}

export enum FILTER_NAMES {
  Can = "can",
  Obd = "obd",

  Id = "id",

  Type = "type",

  Model = "modelNames",
  ProductionYear = "startYear",
  Make = "manufacturers",

  FuelType = "fuelType",
  General = "general",
  Engine = "engine",
  Transmission = "transmission",
  Doors = "doors",
  SeatBelt = "seatBelt",
  Lights = "lights",
  EV = "ev",
  Other = "other",
  Indicator = "indicator",
  Battery = "battery",

  MakeFilter = "manufacturer",
  ModelFilter = "model",
  ExtendedFilter = "extendedFilter",
}

export enum NOT_FILTER_NAMES {
  Query = "query",
  Sort = "sort",
  Order = "order",
  Page = "pageNum",
  PerPage = "perPage",
  Selected = "selected",
}

export enum CHECKBOX_FILTERS {
  Can = "can",
  Obd = "obd",
}

export enum TRANSLATABLE_FILTER_NAMES {
  Can = "can",
  Obd = "obd",
}

export enum ID_TYPE {
  Id = "id",
}

export enum PER_PAGE {
  SmallCount = "25",
  MediumCount = "50",
  LargeCount = "100",
}

export enum ORDERS {
  Ascending = "asc",
  Descending = "desc",
}

export enum ENVIRONMENTS {
  Development = "development",
  Dev = "dev",
  Production = "production",
}

export enum FUEL_TYPES {
  Any,
  Ice,
  EV,
}

export enum CODE_TYPE {
  OBD = "OBD",
  CAN = "CAN",
}

export enum ENCODED_FILTERS {
  Model = "model",
}

export enum ENDPOINTS {
  Codes = "codes",
}

export enum CUSTOM_FILTERS {
  ExtendedFilter = "extendedFilter",
  ProductionYear = "startYear",
  FuelType = "fuelType",
}

export enum CUSTOM_FILTER_NAMES {
  ProductionYear = "Production start year",
  FuelType = "Fuel type",
}

export enum DROPDOWN_SIZES {
  Tiny = "tiny",
  Small = "small",
  Medium = "medium",
}
