import { ParamsInitialState } from "../paramsReducer";

import {
  Filter,
  FilterDropdownOptions,
  FilteredValue,
  isFilter,
  PER_PAGE,
  QueryParamName,
  QueryParams,
  TABLE_NAMES,
  NOT_FILTER_NAMES,
  isValidParamName,
} from "../../../shared";

export const getRowsPerPageValue = (
  perPageValue: string | string[] | undefined,
  resource: TABLE_NAMES
) => {
  const isCorrectValue = (value: any): value is PER_PAGE =>
    Object.values(PER_PAGE).includes(value);

  const getRowsPerPageFromBrowser = () => {
    const value = localStorage.getItem(`${resource}RowsPerPage`);

    if (isCorrectValue(value)) {
      return value === "25" ? "" : value;
    } else {
      localStorage.setItem(`${resource}RowsPerPage`, "25");
      return "";
    }
  };

  if (perPageValue) {
    if (isCorrectValue(perPageValue)) {
      localStorage.setItem(`${resource}RowsPerPage`, perPageValue);

      return perPageValue === "25" ? "" : perPageValue;
    } else {
      return getRowsPerPageFromBrowser();
    }
  } else {
    return getRowsPerPageFromBrowser();
  }
};

export const getActiveQueryParams = (
  isEmpty: boolean,
  rowsPerPageValue: "50" | "100" | "" | undefined,
  state: ParamsInitialState,
  resetParams: boolean,
  updatedParams: QueryParams
) => {
  return isEmpty
    ? {
        [NOT_FILTER_NAMES.PerPage]: rowsPerPageValue,
      }
    : {
        ...(resetParams ? {} : state.queryParams),
        ...updatedParams,
        [NOT_FILTER_NAMES.PerPage]: rowsPerPageValue,
      };
};

export const isWrongQueryParamValueLength = (
  key: QueryParamName,
  value: string
) => (key === NOT_FILTER_NAMES.Query ? value.length < 3 : value.length === 0);

export const removeNonValidQueryParams = (
  queryParamsArray: [QueryParamName, string][],
  activeQueryParams: QueryParams
) => {
  queryParamsArray.forEach(([key, value]) => {
    if (!isValidParamName(key) || isWrongQueryParamValueLength(key, value)) {
      delete activeQueryParams[key];
    }
  });
};

export const getQueryParamsValueLength = (key: QueryParamName, value: string) =>
  key === NOT_FILTER_NAMES.Query ? value.length < 3 : value.length === 0;

export const distributedQueryParams = (params: QueryParams) => {
  const paramsCopy = { ...params };

  return {
    allOtherParams: paramsCopy,
  };
};

export const distributeFilteredValues = (filteredValues?: FilteredValue) => {
  return {
    filteredValues,
  };
};

export const getFilteredValues = (
  currentValues: FilteredValue[],
  newValue: FilteredValue | undefined
) => {
  if (newValue) {
    if (newValue.value?.length) {
      const filterIndex = currentValues.findIndex(
        (val) => val.filterName === newValue.filterName
      );

      if (filterIndex === -1) {
        return [...currentValues, newValue];
      } else {
        currentValues[filterIndex] = newValue;
        return currentValues;
      }
    } else {
      return currentValues.filter(
        (currentValue) => currentValue.filterName !== newValue.filterName
      );
    }
  } else {
    return currentValues;
  }
};

export const getFilterDropdownOptions = (
  currentOptions: FilterDropdownOptions,
  newOptions: FilterDropdownOptions | Filter[] | undefined
) => {
  if (newOptions) {
    return { ...currentOptions, ...newOptions };
  } else {
    return currentOptions;
  }
};

export const removeParams = (
  paramNames: QueryParamName[],
  updatedQueryParams: QueryParams
) => {
  const removableParams: QueryParams = {};

  const getCondition = (key: QueryParamName) => {
    return isFilter(key) || key === NOT_FILTER_NAMES.Query;
  };

  paramNames.forEach((key) => {
    const currentCondition = getCondition(key);

    if (currentCondition) {
      removableParams[key] = "";
      delete updatedQueryParams[key];
    }
  });

  return removableParams;
};

export const getUpdatedReloadItems = (
  reloadItems: { [name in TABLE_NAMES]?: boolean },
  payload: TABLE_NAMES | TABLE_NAMES[]
) => {
  const currentReloadItems = { ...reloadItems };

  const tableNames = Array.isArray(payload) ? payload : [payload];

  tableNames.forEach((tableName) => {
    currentReloadItems[tableName] = !currentReloadItems[tableName];
  });

  return currentReloadItems;
};
