import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";

export const useStyleOptions = (
  isSelected: boolean,
  onDelete?: (event: any) => void
) => {
  const {
    colorsFacelift: {
      blueGray100,
      blueGray200,

      blueGray700,

      blue50,
      blue100,
      blue200,
      blue700,
      blue800,
      blue900,
      textDark,
      white,
    },
  } = useContext(ThemeContext);

  const styleVariants = {
    colors: {
      primary: {
        backgroundRest: isSelected ? blue700 : blue50,
        backgroundHover: isSelected ? blue800 : blue100,
        backgroundActive: isSelected ? blue900 : blue200,
        textRest: isSelected ? white : blue700,
        textActive: isSelected ? white : blue700,
        border: isSelected ? blue700 : blue50,
        borderHover: isSelected ? blue800 : blue100,
        borderActive: isSelected ? blue900 : blue200,
        icon: isSelected ? white : blue700,
        iconHover: blue800,
        iconActive: blue900,
      },
      white: {
        backgroundRest: white,
        backgroundHover: blueGray100,
        backgroundActive: blueGray200,
        textRest: blueGray700,
        textActive: textDark,
        border: blueGray200,
        borderHover: blueGray200,
        borderActive: blueGray200,
        icon: blueGray700,
        iconHover: blueGray700,
        iconActive: blueGray700,
      },
    },
    paddings: {
      medium: "5px 8px",
      small: "1px 8px",
      tiny: onDelete ? "1px 4px 1px 8px" : "1px 8px",
    },
  };

  return styleVariants;
};
