/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, Fragment, useState, useEffect } from "react";
import moment from "moment";
import UpdateIcon from "@mui/icons-material/Update";
import Skeleton from "@mui/material/Skeleton/Skeleton";

import Logo from "../Logo";

import ThemeContext from "../../context/theme/themeContext";

const Navbar = () => {
  const {
    colorsFacelift: { textDark, white, blueGray200 },
  } = useContext(ThemeContext);

  const [isLoading, setLoading] = useState(true);

  const yesterdayDate = moment().subtract(1, "day").format("Do MMMM YYYY");

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <Fragment>
      <header
        css={css({
          position: "sticky",
          top: "0",
          zIndex: "1",
          background: white,
        })}
      >
        <div
          css={css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 24px",
            borderBottom: `1px solid ${blueGray200}`,
            height: "36px",
          })}
        >
          <Logo />

          <div>
            {!isLoading ? (
              <div
                css={css({
                  display: "flex",
                  alignItems: "center",
                  color: textDark,
                  gap: "8px",

                  span: {
                    fontWeight: 600,
                    lineHeight: "20px",
                    fontSize: "14px",
                    letterSpacing: "0.1px",
                    paddingRight: "12px",
                  },
                })}
              >
                <UpdateIcon />
                <span>Vehicle list updated at: {yesterdayDate}</span>
              </div>
            ) : (
              <Skeleton
                variant="rounded"
                width={340}
                height={36}
                css={css({
                  borderRadius: "46px",
                })}
              />
            )}
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Navbar;
