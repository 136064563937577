import { useContext } from "react";
import MuiMenu from "@mui/material/Menu";
import { CombinedMenuProps } from "./types";
import ThemeContext from "../../../context/theme/themeContext";

const Menu = ({ children, ...props }: CombinedMenuProps) => {
    const {
        colorsFacelift: { blueGray200, white }
    } = useContext(ThemeContext);

    return (
        <MuiMenu
            {...props}
            sx={{
                "& .MuiMenu-paper": {
                    background: white,
                    border: `1px solid ${blueGray200}`,
                    borderRadius: "6px",
                    boxShadow: "0px 2px 8px rgba(15, 23, 42, 0.16)"
                },

                "& .MuiMenu-list": {
                    padding: "0"
                }
            }}
        >
            {children}
        </MuiMenu>
    );
};

export default Menu;
