import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CanadapterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect width="24" height="24" rx="4" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H14.5C15.6046 19 16.5 18.1046 16.5 17V7C16.5 5.89543 15.6046 5 14.5 5H5ZM14.5 6.75H5C4.86193 6.75 4.75 6.86193 4.75 7V17C4.75 17.1381 4.86193 17.25 5 17.25H14.5C14.6381 17.25 14.75 17.1381 14.75 17V7C14.75 6.86193 14.6381 6.75 14.5 6.75Z"
        fill="#697D95"
      />
      <path
        d="M16.5 7H18.916C19.402 7 19.8153 7.17 20.156 7.51C20.496 7.85067 20.666 8.264 20.666 8.75V15.25C20.666 15.736 20.496 16.1493 20.156 16.49C19.8153 16.83 19.402 17 18.916 17H16.5V15.271H18.916V8.729H16.5V7Z"
        fill="#697D95"
      />
      <path
        d="M19 13.646L19 12.833L17.792 12.833C17.6807 12.833 17.587 12.8713 17.511 12.948C17.4343 13.024 17.396 13.1177 17.396 13.229C17.396 13.3403 17.4307 13.4377 17.5 13.521C17.57 13.6043 17.6603 13.646 17.771 13.646L19 13.646Z"
        fill="#697D95"
      />
      <path
        d="M19 11.188L19 10.354L17.813 10.354C17.7017 10.354 17.6043 10.3957 17.521 10.479C17.4377 10.5623 17.396 10.6597 17.396 10.771C17.396 10.8823 17.4343 10.9793 17.511 11.062C17.587 11.146 17.6807 11.188 17.792 11.188L19 11.188Z"
        fill="#697D95"
      />
    </SvgIcon>
  );
};

export default CanadapterIcon;
