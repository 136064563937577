import { createRoot } from "react-dom/client";
import WebFont from "webfontloader";

import App from "./App";

WebFont.load({
  google: {
    families: ["Open Sans:300,400,600,700,800", "sans-serif"],
  },
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);
