import Routes from "./components/Routes";
import ParamsState from "./context/params/ParamsState";
import ThemeState from "./context/theme/ThemeState";

const App = () => {
  return (
    <ThemeState>
      <ParamsState>
        <Routes />
      </ParamsState>
    </ThemeState>
  );
};

export default App;
