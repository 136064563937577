/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";
import ThemeContext from "../../context/theme/themeContext";

const FilteredValuesLoader = () => {
  const {
    colorsFacelift: { blueGray200, blueGray100 },
  } = useContext(ThemeContext);

  return (
    <div
      css={css({
        display: "flex",
        alignItems: "center",
        borderTop: `1px solid ${blueGray200}`,
        padding: "10px 24px 10px 24px",

        span: {
          borderRadius: "6px",
          background: blueGray100,
        },
      })}
    >
      <Skeleton variant="rectangular" width={77} height={20} />

      <div
        css={css({
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          columnGap: "6px",
          rowGap: "6px",
          marginLeft: "16px",
        })}
      >
        <Skeleton variant="rectangular" width={140} height={32} />

        <Skeleton variant="rectangular" width={140} height={32} />

        <Skeleton variant="rectangular" width={80} height={20} />
      </div>
    </div>
  );
};

export default FilteredValuesLoader;
