import { useContext, useState, useEffect } from "react";

import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { useFilterDropdown } from "./hooks";
import FilterListBox from "./FilterListBox";

import Autocomplete from "../../DesignComponents/Autocomplete";
import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
  Filter,
  filterDropdownCustomWidth,
  FILTER_NAMES,
  DROPDOWN_SIZES,
} from "../../../shared";
import RectangleLoader from "../../../shared/loaders/RectangleLoader";

interface FilterDropdownProps {
  filter: FILTER_NAMES;
  placeholder: string;
  customFilterName?: FILTER_NAMES;
}

const FilterDropdown = ({
  filter,
  placeholder,
  customFilterName,
  ...props
}: FilterDropdownProps &
  Omit<
    MuiAutocompleteProps<any, boolean, boolean, boolean>,
    "renderInput" | "size" | "options"
  >) => {
  const { filteredValues: mainFilteredValues, setQueryParams } =
    useContext(ParamsContext);

  const { deselectAllRows, isInitialLoading } = useContext(TableContext);

  const { isOpen, isLoading, options, setOpen } = useFilterDropdown(filter);

  const [value, setValue] = useState<Filter[]>([]);

  const filteredValues = mainFilteredValues;

  // Set value
  useEffect(() => {
    const actualFilter = filteredValues.find(
      (filteredValue) => filteredValue.filterName === filter
    );

    setValue(actualFilter ? actualFilter.value : []);
  }, [filteredValues, filter]);

  //If needed change to singleValue.name in the future
  const handleChange = (newValue: Filter[]) => {
    const paramsPayload = {
      [customFilterName ?? filter]:
        filter === FILTER_NAMES.Model
          ? newValue.map((singleValue) =>
              encodeURIComponent(String(singleValue.name))
            )
          : newValue.map((singleValue) => String(singleValue.id)),
    };

    const filteredValuesPayload = {
      filterName: filter,
      value: newValue,
    };

    setQueryParams(paramsPayload, true, filteredValuesPayload);

    deselectAllRows();
  };

  const getOptionLabel = (option: Filter) => {
    const name = String(option.name);

    return name;
  };

  const loadingIcon = isLoading ? <CircularProgress size={16} /> : null;

  const checkIfEqualToChoice = (option: any, val: any) => {
    return filter !== FILTER_NAMES.Model
      ? option.id === val.id
      : option.name === val.name;
  };

  return isInitialLoading ? (
    <RectangleLoader customStyle={filterDropdownCustomWidth} height={36} />
  ) : (
    <Autocomplete
      {...props}
      fullWidth
      multiple
      size={DROPDOWN_SIZES.Small}
      includeInputInList
      disableClearable
      disableCloseOnSelect
      open={isOpen}
      value={value}
      isOptionEqualToValue={checkIfEqualToChoice}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={isLoading}
      onChange={(_, newFilterValue) => handleChange(newFilterValue)}
      iconRight={loadingIcon}
      ListboxComponent={FilterListBox}
      renderTags={() => null}
      textFieldParams={{
        placeholder,
      }}
      getOptionLabel={(option: Filter) => getOptionLabel(option)}
    />
  );
};

export default FilterDropdown;
