import axios from "axios";
import { useDecoder } from "../useDecoder";

export const useApi = () => {
  const { decodeStrings } = useDecoder();

  const CancelToken = axios.CancelToken;
  const cancelSource = CancelToken.source();
  const isCanceled = axios.isCancel;

  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    cancelToken: cancelSource.token,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
    xsrfHeaderName: "X-XSRF-TOKEN",
    xsrfCookieName: "XSRF-TOKEN",
  });

  const getData = (resource: string) => api.get(resource);

  const queryData = (
    resource: string,
    stringParams: string,
    hasDefaultSort: boolean = false
  ) => {
    const stringParamsExist = stringParams.length > 0;
    const ampersand = stringParamsExist ? "&" : "";
    const ampersandOrQuestion = resource.includes("?") ? "&" : "?";
    const queryExists = stringParamsExist || hasDefaultSort;
    const sortById = hasDefaultSort ? `${ampersand}order=desc&sort=id` : "";

    const stringParamsWithQuotes = decodeStrings(stringParams, queryExists);
    return api.get(
      `${resource}${
        queryExists ? ampersandOrQuestion : ""
      }${stringParamsWithQuotes}${sortById}`
    );
  };

  return {
    cancelSource,
    isCanceled,
    getData,
    queryData,
  };
};
