import { COLORS } from "../types";

export const ALL_COLORS = {
    black: COLORS.Black,
    blue50: COLORS.Blue50,
    blue100: COLORS.Blue100,
    blue200: COLORS.Blue200,
    blue300: COLORS.Blue300,
    blue700: COLORS.Blue700,
    blue800: COLORS.Blue800,
    blue900: COLORS.Blue900,
    blueGray50: COLORS.BlueGray50,
    blueGray100: COLORS.BlueGray100,
    blueGray200: COLORS.BlueGray200,
    blueGray300: COLORS.BlueGray300,
    blueGray400: COLORS.BlueGray400,
    blueGray500: COLORS.BlueGray500,
    blueGray600: COLORS.BlueGray600,
    blueGray700: COLORS.BlueGray700,
    blueSideNavigation: COLORS.BlueSideNavigation,
    cyan300: COLORS.Cyan300,
    cyan600: COLORS.Cyan600,
    green50: COLORS.Green50,
    green100: COLORS.Green100,
    green200: COLORS.Green200,
    green300: COLORS.Green300,
    green400: COLORS.Green400,
    green500: COLORS.Green500,
    green600: COLORS.Green600,
    green700: COLORS.Green700,
    green800: COLORS.Green800,
    green900: COLORS.Green900,
    grey80: COLORS.Grey80,
    inkNormal: COLORS.InkNormal,
    orange50: COLORS.Orange50,
    orange100: COLORS.Orange100,
    orange200: COLORS.Orange200,
    orange300: COLORS.Orange300,
    orange400: COLORS.Orange400,
    orange500: COLORS.Orange500,
    orange600: COLORS.Orange600,
    orange700: COLORS.Orange700,
    purple50: COLORS.Purple50,
    purple100: COLORS.Purple100,
    purple700: COLORS.Purple700,
    purple900: COLORS.Purple900,
    red50: COLORS.Red50,
    red100: COLORS.Red100,
    red200: COLORS.Red200,
    red300: COLORS.Red300,
    red600: COLORS.Red600,
    red700: COLORS.Red700,
    red800: COLORS.Red800,
    red900: COLORS.Red900,
    textDark: COLORS.TextDark,
    textDarkDisabled: COLORS.TextDarkDisabled,
    textDarkSecondary: COLORS.TextDarkSecondary,
    textIconDark: COLORS.TextIconDark,
    textPlaceholder: COLORS.TextPlaceholder,
    textSuccess: COLORS.TextSuccess,
    textWarning: COLORS.TextWarning,
    textWhite: COLORS.TextWhite,
    white: COLORS.White
};
