import qs from "query-string";

import { useWindowLocation } from "./useWindowLocation";
import { QueryParamName, QueryParams } from "../types";
import { isValidParamName } from "../functions";

export const useUrlQueryParams = () => {
  const { pathname, searchStrings } = useWindowLocation();

  const sendParamsToUrl = (params: QueryParams) => {
    const parsedUrlParams = qs.parse(searchStrings, {
      arrayFormat: "comma",
    });

    const updatedUrlParams = { ...parsedUrlParams, ...params };

    Object.entries(params).forEach(([paramName, paramValue]) => {
      (!isValidParamName(paramName) || paramValue.length === 0) &&
        delete updatedUrlParams[paramName as QueryParamName];
    });

    const stringified = qs.stringify(updatedUrlParams, {
      arrayFormat: "comma",
    });

    const url = stringified.length > 0 ? `?${stringified}` : pathname;

    window.history.replaceState(null, "", url);
  };

  const getUrlParams = () =>
    qs.parse(searchStrings, {
      arrayFormat: "comma",
    });

  return {
    sendParamsToUrl,
    getUrlParams,
  };
};
