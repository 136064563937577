import { useContext, useEffect, useState } from "react";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";

import FilterListBox from "../../FilterDropdown/FilterListBox";
import Autocomplete from "../../../DesignComponents/Autocomplete";
import ParamsContext from "../../../../context/params/paramsContext";
import { DROPDOWN_SIZES, FILTER_NAMES, Filter } from "../../../../shared";

interface QueryAutocompleteProps {
  filterName: FILTER_NAMES;
  options: Filter[];
  placeholder: string;
}

const QueryAutocomplete = ({
  filterName,
  options,
  placeholder,
  ...props
}: QueryAutocompleteProps &
  Omit<
    MuiAutocompleteProps<any, boolean, boolean, boolean>,
    "renderInput" | "size" | "options"
  >) => {
  const { filteredValues: mainFilteredValues, setQueryParams } =
    useContext(ParamsContext);

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<Filter[]>([]);

  useEffect(() => {
    const actualFilter = mainFilteredValues.find(
      (filteredValue) => filteredValue.filterName === filterName
    );

    setValue(actualFilter ? actualFilter.value : []);
  }, [mainFilteredValues, filterName]);

  const handleChange = (newValue: Filter[]) => {
    const paramsPayload = {
      [filterName]: newValue.map((singleValue) => String(singleValue.id)),
    };

    const filteredValuesPayload = {
      filterName: filterName,
      value: newValue,
    };

    setQueryParams(paramsPayload, true, filteredValuesPayload);
  };

  return (
    <Autocomplete
      {...props}
      disableCloseOnSelect
      clearOnBlur
      fullWidth
      multiple
      size={DROPDOWN_SIZES.Small}
      includeInputInList
      disableClearable
      open={isOpen}
      value={value}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={options}
      onChange={(_, newFilterValue) => handleChange(newFilterValue)}
      ListboxComponent={FilterListBox}
      renderTags={() => null}
      textFieldParams={{
        placeholder,
      }}
      getOptionLabel={(option: Filter) => String(option.name)}
    />
  );
};

export default QueryAutocomplete;
