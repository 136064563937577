/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  useContext,
  useState,
  SyntheticEvent,
  useEffect,
  useCallback,
} from "react";
import Tab from "@mui/material/Tab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import _ from "lodash";

import VehicleInfo from "./VehicleInfo";
import Tabs from "../DesignComponents/Tabs";

import ThemeContext from "../../context/theme/themeContext";
import TableContext from "../../context/table/tableContext";
import { CODE_TYPE } from "../../shared";
import RectangleLoader from "../../shared/loaders/RectangleLoader";

const VehicleDetails = () => {
  const {
    colorsFacelift: { blueGray200, textDark, white },
  } = useContext(ThemeContext);

  const { detailsData } = useContext(TableContext);

  const [tabValue, setTabValue] = useState(1);

  const [groupedData, setGroupedData] = useState<any>({
    [CODE_TYPE.CAN]: [],
    [CODE_TYPE.OBD]: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const getGroupedValues = useCallback(
    (tabValue: number) => {
      const { codes } = detailsData;
      const filteredData = codes.filter((entry: any) => {
        return entry.codeType === tabValue;
      });

      const grouped = _.map(_.mapValues(_.groupBy(filteredData, "codeGroup")));
      return grouped;
    },
    [detailsData]
  );

  const setDefaultTabValue = useCallback(() => {
    const canValues = getGroupedValues(1);
    const obdValues = getGroupedValues(0);
    const defaultTab = canValues.length === 0 ? 0 : 1;

    setTabValue(defaultTab);
    setGroupedData({
      [CODE_TYPE.CAN]: obdValues,
      [CODE_TYPE.OBD]: canValues,
    });
    setIsLoading(false);
  }, [getGroupedValues, setTabValue, setGroupedData]);

  useEffect(() => {
    if (detailsData) {
      setDefaultTabValue();
    } else {
      setGroupedData({
        [CODE_TYPE.CAN]: [],
        [CODE_TYPE.OBD]: [],
      });
    }
  }, [detailsData, setDefaultTabValue]);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderVehicleDetails = () => {
    const filteredData =
      tabValue === 0 ? groupedData[CODE_TYPE.CAN] : groupedData[CODE_TYPE.OBD];

    if (isLoading) {
      detailsData === undefined && setIsLoading(false);
      return (
        <>
          <RectangleLoader
            width={171}
            height={24}
            customStyle={{ marginBottom: "82px" }}
          />
          <Tabs
            value={tabValue}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            css={css({
              padding: "16px 0 24px 0",
            })}
          >
            {[1, 0].map((tabValue, index) => (
              <Tab
                key={tabValue}
                value={tabValue}
                label={<RectangleLoader width={42} noMargin />}
                disabled
                css={css({
                  width: "50%",
                })}
              />
            ))}
          </Tabs>

          <div
            css={css({
              border: `1px ${blueGray200} solid`,
              borderRadius: "8px",
            })}
          >
            {[422, 295, 280].map((margin) => (
              <div
                css={css({
                  borderBottom: `1px ${blueGray200} solid`,
                  padding: "16px",
                })}
                key={margin}
              >
                <RectangleLoader
                  width={64}
                  height={20}
                  customStyle={{ marginBottom: `${margin}px` }}
                />
              </div>
            ))}
          </div>
        </>
      );
    }

    return (
      detailsData && (
        <>
          <div
            css={css({
              display: "grid",
              alignItems: "center",

              "span:first-of-type": {
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                color: textDark,
                marginBottom: "16px",
              },
            })}
          >
            <span>{detailsData?.name}</span>
            <span>
              Standard parameters ( V ) are supported in all OBD/CAN devices.
              Contact your Teltonika sales manager for the 'Extended' parameters
              package.
            </span>
          </div>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            css={css({
              padding: "16px 0 24px 0",
            })}
          >
            <Tab
              value={1}
              label={CODE_TYPE.CAN}
              css={css({
                width: "50%",
              })}
            />

            <Tab
              value={0}
              label={CODE_TYPE.OBD}
              css={css({
                width: "50%",
              })}
            />
          </Tabs>
          <div className="VehicleInfoWindow">
            {filteredData.length > 0 ? (
              filteredData.map((entry: any, index: number) => {
                return (
                  <VehicleInfo
                    data={entry}
                    index={index}
                    isFirst={index === 0}
                    isLast={index === filteredData.length - 1}
                    key={`${entry[0].id}_${entry[0].codeGroup}`}
                  />
                );
              })
            ) : (
              <div
                css={css({
                  border: `1px ${blueGray200} solid`,
                  borderRadius: "8px",
                  display: "flex",
                  padding: "16px",
                  alignItems: "center",

                  span: {
                    paddingLeft: "8px",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    color: textDark,
                  },
                })}
              >
                <InfoOutlinedIcon />
                <span>Parameters not supported</span>
              </div>
            )}
          </div>
        </>
      )
    );
  };

  return (
    <div
      css={css({
        background: white,
        borderLeft: `1px solid var(--blue-gray-blue-gray-200, ${blueGray200})`,
        height: `calc(100vh - 56px)`,

        width: `520px`,
        boxShadow: "0px 1px 4px 0px rgba(15, 23, 42, 0.12)",
        right: 0,
        position: "fixed",
      })}
    >
      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "16px 24px",
        })}
      >
        {renderVehicleDetails()}
      </div>
    </div>
  );
};

export default VehicleDetails;
