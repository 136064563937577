/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Skeleton } from "@mui/material";
import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";

interface IRectangleLoaderProps {
  width?: string | number;
  height?: string | number;
  customStyle?: any;
  backgroundColor?: string;
  borderRadius?: string;
  noMargin?: boolean;
}

const RectangleLoader = ({
  width,
  height,
  customStyle,
  backgroundColor,
  borderRadius,
  noMargin,
}: IRectangleLoaderProps) => {
  const {
    colorsFacelift: { blueGray100 },
  } = useContext(ThemeContext);

  return (
    <Skeleton
      data-testid="rectangle-loader"
      variant="rectangular"
      width={width}
      height={height}
      css={css([
        {
          background: backgroundColor ?? blueGray100,
          borderRadius: borderRadius ?? "6px",
          marginRight: noMargin ? "" : "16px",
        },
        customStyle,
      ])}
    />
  );
};

export default RectangleLoader;
