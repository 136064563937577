/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import NoResultsFound from "./NoResultsFound";
import NoInitialResults from "./NoInitialResults";
import ThemeContext from "../../context/theme/themeContext";
import ParamsContext from "../../context/params/paramsContext";
import { isFilter } from "../../shared";

const TableEmpty = () => {
  const {
    colorsFacelift: { blueGray700, blue100, blue50, textDark },
  } = useContext(ThemeContext);

  const { queryParams } = useContext(ParamsContext);

  const isFilterUsed = Object.keys(queryParams).some((param) =>
    isFilter(param)
  );

  return (
    <div className="remaining-height">
      <div
        css={css({
          height: "42px",
          boxSizing: "border-box",
          background: blue50,
          borderTop: `1px solid ${blue100}`,
          borderBottom: `1px solid ${blue100}`,
          borderRadius: "0",
        })}
      />

      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: "1",
          padding: "32px 24px",

          "& > div:nth-of-type(1)": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "16px",
          },

          "& > div:nth-of-type(2)": {
            color: textDark,
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0.15px",
            marginBottom: "8px",
          },

          "& > div:nth-of-type(3)": {
            color: blueGray700,
            textAlign: "center",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            marginBottom: "12px",
            maxWidth: "311px",
          },
        })}
      >
        {isFilterUsed ? <NoResultsFound /> : <NoInitialResults />}
      </div>
    </div>
  );
};

export default TableEmpty;
