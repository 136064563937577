export const filterBlockStyle = {
  "@media(max-width: 839px)": {
    flex: "1",
  },

  "@media(max-width: 599px)": {
    flex: "unset",
    marginLeft: "8px",
  },
};

export const vehicleDropdownStyle = {
  display: "flex",
  alignItems: "center",
  ...filterBlockStyle,
};

export const filterDropdownCustomWidth = {
  width: "246px",
  transition: "width 300ms ease-out",

  "@media(max-width: 1199px)": {
    width: "200px",
    flex: "1",
  },
};

export const filterDropdownCustomMargin = {
  marginRight: "16px",

  "@media(max-width: 839px)": {
    marginRight: "0",
  },
};
