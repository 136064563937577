import qs from "query-string";

import {
  CHECKBOX_FILTERS,
  ENVIRONMENTS,
  Filter,
  FILTER_NAMES,
  NOT_FILTER_NAMES,
  QueryParamName,
  QueryParams,
  Resource,
  TRANSLATABLE_FILTER_NAMES,
} from "../";

export const noop = () => {
  // This is intentional
};

export const isValidParamName = (paramName: string) => {
  const allNames = Object.values({ ...FILTER_NAMES, ...NOT_FILTER_NAMES });
  return allNames.includes(paramName as any);
};

export const isFilter = (paramName: string) =>
  Object.values(FILTER_NAMES).includes(paramName as any);

export const isTranslatableFilter = (filterName: string) =>
  Object.values(TRANSLATABLE_FILTER_NAMES).includes(filterName as any);

export const isCheckboxFilter = (filterName: string) =>
  Object.values(CHECKBOX_FILTERS).includes(filterName as any);

export const getFilteredQueryParams = (params: QueryParams) => {
  const allParams = { ...params };
  const allParamNames = Object.keys(allParams) as QueryParamName[];

  allParamNames.forEach((paramName) => {
    const mustBeDeleted = !isFilter(paramName);

    if (mustBeDeleted) {
      delete allParams[paramName];
    }
  });

  return allParams;
};

export const getStringifiedParams = (params: QueryParams) => {
  const copyParams = { ...params };
  delete copyParams[NOT_FILTER_NAMES.Selected];

  return qs.stringify(copyParams, {
    arrayFormat: "comma",
  });
};

export const addNotAssigned = (options: Filter[]) =>
  options.forEach((field: Filter, i: number) => {
    if (field.id === null) {
      options.splice(i, 1);
      options.unshift({
        id: "null",
        name: "not assigned",
      });
    }
  });

export const parseResource = (resource: Resource, data: any): string | null => {
  const isNestedResource = Array.isArray(resource);

  if (isNestedResource) {
    return resource.reduce((object, objectKey) => {
      return object !== null ? object[objectKey] : null;
    }, data);
  } else {
    return data[resource];
  }
};

export const generateColor = (
  inverted: boolean | undefined,
  firstcolor: string,
  secondColor: string
) => (inverted ? firstcolor : secondColor);

export const getEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  return {
    env: env,
    isProductionEnv: env === ENVIRONMENTS.Production,
    isNotDevelopmentEnv: env !== ENVIRONMENTS.Development,
  };
};
