export enum CODE_GROUP {
  General,
  Engine,
  Transmission,
  Doors,
  Seats,
  Lights,
  EV,
  Other,
  Indicator,
  Battery,
}
