/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { useContext } from "react";

import {
  TOOLTIP_PLACEMENT,
  TooltipGeneral,
} from "../../../../DesignComponents/Tooltips";
import ThemeContext from "../../../../../context/theme/themeContext";
import CanAdapterIcon from "../../../../../assets/customIcons/infoIcons/CanAdapterIcon";
import ObdIcon from "../../../../../assets/customIcons/infoIcons/ObdIcon";
import { CODE_TYPE } from "../../../../../shared";

interface InfoIconsProps {
  css?: SerializedStyles;
  codesData?: any;
}

const InfoIcons = ({ codesData, ...props }: InfoIconsProps) => {
  const {
    colorsFacelift: { blueGray200, blueGray100 },
  } = useContext(ThemeContext);

  const checkIfIconExists = (iconId: number) => {
    return codesData.some((entry: any) => entry.codeType === iconId);
  };

  return (
    <div
      {...props}
      css={css({
        display: "flex",

        span: {
          display: "inline-flex",
        },

        "span + span": {
          marginLeft: "4px",
        },

        "span:hover svg": {
          color: blueGray200,
        },

        svg: {
          transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: blueGray100,
        },
      })}
    >
      {checkIfIconExists(1) && (
        <TooltipGeneral title={CODE_TYPE.CAN} placement={TOOLTIP_PLACEMENT.Top}>
          <span>
            <CanAdapterIcon />
          </span>
        </TooltipGeneral>
      )}

      {checkIfIconExists(0) && (
        <TooltipGeneral title={CODE_TYPE.OBD} placement={TOOLTIP_PLACEMENT.Top}>
          <span>
            <ObdIcon />
          </span>
        </TooltipGeneral>
      )}
    </div>
  );
};

export default InfoIcons;
