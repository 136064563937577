import { useContext } from "react";

import InfoIcons from "../components/TableItem/IconValues/InfoIcons";
import ParamsContext from "../../../context/params/paramsContext";
import {
  Column,
  COLUMN_NAMES,
  COLUMN_RESOURCES,
  FUEL_TYPES,
  InitialColumn,
  NOT_FILTER_NAMES,
  parseResource,
  Resource,
  TABLE_NAMES,
} from "../../../shared";

export const useTableFunctions = () => {
  const { setQueryParams } = useContext(ParamsContext);

  const addIsActiveToColumns = (columns: InitialColumn[]) =>
    columns.map((column) => ({
      ...column,
      isActive: Boolean(column.isDefaultActive),
    }));

  const getIsActiveFromLocalStorage = (
    name: TABLE_NAMES,
    columnName: string,
    defaultValue: boolean
  ) => {
    const isJson = (columnsFromStorage: string) => {
      try {
        JSON.parse(columnsFromStorage);

        if (Object.keys(JSON.parse(columnsFromStorage)).length > 0) {
          return true;
        } else {
          localStorage.removeItem(`${name}Columns`);
          return false;
        }
      } catch (error) {
        localStorage.removeItem(`${name}Columns`);
        return false;
      }
    };

    const columns = localStorage.getItem(`${name}Columns`);

    if (columns) {
      return isJson(columns)
        ? JSON.parse(columns)[columnName] || defaultValue
        : defaultValue;
    }

    return defaultValue;
  };

  const renderColumnsWithIsActive = (
    name: TABLE_NAMES,
    initialColumns: InitialColumn[]
  ) => {
    const columnsWithIsActive = addIsActiveToColumns(initialColumns);

    return columnsWithIsActive.map((column: Column) => ({
      ...column,
      isActive: getIsActiveFromLocalStorage(name, column.name, column.isActive),
    }));
  };

  const renderStringResource = (
    name: COLUMN_NAMES,
    value: string | number | null,
    isTranslatable?: boolean
  ) => {
    if (value === null || value === "") {
      return "-";
    }

    if (isTranslatable) {
      return name === "field" ? String(value).toLowerCase() : value;
    }

    return value;
  };

  const renderValue = (
    name: COLUMN_NAMES,
    resource: Resource,
    data: any,
    isTranslatable: boolean = false
  ) => {
    switch (resource) {
      case COLUMN_RESOURCES.InfoIcons:
        return <InfoIcons codesData={data?.codes} />;

      case COLUMN_RESOURCES.FuelType:
        return FUEL_TYPES[data.fuelType];

      case COLUMN_RESOURCES.ProductionYear:
        return data.EndYear
          ? `${data.startYear} - ${data.endYear}`
          : `${data.startYear} - Present`;

      default: {
        const parsedValue = parseResource(resource, data);
        return renderStringResource(name, parsedValue, isTranslatable);
      }
    }
  };

  const handlePageChange = (newPage: number) => {
    const pageValue = newPage === 1 ? "" : String(newPage);
    const pageQueryParam = { [NOT_FILTER_NAMES.Page]: pageValue };

    setQueryParams(
      pageQueryParam,
      false,
      undefined,
      undefined,
      undefined,
      true
    );
  };

  return {
    renderColumnsWithIsActive,
    renderValue,
    handlePageChange,
  };
};
