import { TooltipGeneral } from ".";
import {
    TooltipOptionalProps,
    TOOLTIP_SIZES,
    TooltipGeneralProps
} from "./types";

export const TooltipOptional = ({
    children,
    isHidden,
    ...props
}: TooltipOptionalProps & TooltipGeneralProps) => {
    if (!isHidden) {
        return (
            <TooltipGeneral {...props} size={TOOLTIP_SIZES.Medium}>
                {children}
            </TooltipGeneral>
        );
    }
    return children;
};
