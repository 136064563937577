/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import ActionBar from "../Actions/ActionBar";
import VehicleDetails from "../VehicleDetails";

const PageLayout = () => {
  return (
    <div
      css={css({
        display: "flex",
        zIndex: 999,
      })}
    >
      <ActionBar />
      <VehicleDetails />
    </div>
  );
};

export default PageLayout;
