import {
  SET_RESOURCE,
  RESET_ALL_PARAMS,
  SET_QUERY_PARAMS,
  SET_FILTERED_VALUES,
  RELOAD_ITEMS,
} from "./paramsActions";

import {
  getActiveQueryParams,
  getFilterDropdownOptions,
  getFilteredValues,
  getRowsPerPageValue,
  getUpdatedReloadItems,
  removeNonValidQueryParams,
  removeParams,
} from "./functions";

import {
  getFilteredQueryParams,
  useUrlQueryParams,
  useWindowLocation,
  QueryParams,
  FilterQueryParams,
  FilteredValue,
  QueryParamName,
  TABLE_NAMES,
  FilterDropdownOptions,
  getStringifiedParams,
} from "../../shared";

export interface ParamsInitialState {
  resource: TABLE_NAMES | null;
  filteredItemsCount: number;
  queryParams: QueryParams;
  filterDropdownOptions: FilterDropdownOptions;
  filterQueryParams: FilterQueryParams;
  filteredValues: FilteredValue[];
  stringParams: string;
  tableRowDeselectionToggle: boolean;
  reloadItems: {
    [name in TABLE_NAMES]?: boolean;
  };
}

type Action =
  | {
      type: typeof SET_RESOURCE;
      payload: TABLE_NAMES;
    }
  | {
      type: typeof RESET_ALL_PARAMS;
    }
  | {
      type: typeof SET_QUERY_PARAMS;
      payload: {
        resetParams: boolean;
        removeFilterOptions: boolean;
        updatedParams: QueryParams;
        filteredValues: FilteredValue | undefined;
      };
    }
  | {
      type: typeof SET_FILTERED_VALUES;
      payload: {
        data: FilteredValue[];
        filterDropdownOptions: FilterDropdownOptions | undefined;
      };
    }
  | {
      type: typeof RELOAD_ITEMS;
      payload: TABLE_NAMES | TABLE_NAMES[];
    };

const ParamsReducer = (
  state: ParamsInitialState,
  action: Action
): ParamsInitialState => {
  const { sendParamsToUrl } = useUrlQueryParams();
  const { resource } = useWindowLocation();

  switch (action.type) {
    case SET_RESOURCE: {
      return {
        ...state,
        resource: action.payload,
      };
    }
    case SET_QUERY_PARAMS: {
      const {
        resetParams,
        removeFilterOptions,
        updatedParams,
        filteredValues,
      } = action.payload;

      const isEmpty = Object.keys(updatedParams).length === 0;

      const rowsPerPageValue = getRowsPerPageValue(
        updatedParams.perPage,
        resource
      );

      const activeQueryParams = getActiveQueryParams(
        isEmpty,

        rowsPerPageValue,
        state,
        resetParams,
        updatedParams
      );

      const queryParamsArray = Object.entries(activeQueryParams) as [
        QueryParamName,
        string
      ][];

      removeNonValidQueryParams(queryParamsArray, activeQueryParams);

      const stringifiedParams = getStringifiedParams(activeQueryParams);

      sendParamsToUrl({ ...updatedParams, perPage: rowsPerPageValue });

      const updatedFilteredValues = getFilteredValues(
        [...state.filteredValues],
        filteredValues
      );

      return {
        ...state,
        resource: isEmpty ? null : state.resource,
        queryParams: activeQueryParams,
        filterDropdownOptions: removeFilterOptions
          ? {}
          : state.filterDropdownOptions,
        filterQueryParams: getFilteredQueryParams(activeQueryParams),
        filteredValues: updatedFilteredValues,
        stringParams: stringifiedParams,
      };
    }
    case SET_FILTERED_VALUES: {
      const { data, filterDropdownOptions } = action.payload;

      return {
        ...state,
        filterDropdownOptions: getFilterDropdownOptions(
          state.filterDropdownOptions,
          filterDropdownOptions
        ),
        filteredValues: data,
      };
    }

    case RESET_ALL_PARAMS: {
      const updatedQueryParams = { ...state.queryParams };
      const paramNames = Object.keys(updatedQueryParams) as QueryParamName[];

      const removableParams = removeParams(paramNames, updatedQueryParams);
      const stringifiedParams = getStringifiedParams(updatedQueryParams);

      sendParamsToUrl(removableParams);

      return {
        ...state,
        queryParams: updatedQueryParams,
        filterQueryParams: {},
        filteredValues: [],
        stringParams: stringifiedParams,
      };
    }

    case RELOAD_ITEMS: {
      return {
        ...state,
        reloadItems: getUpdatedReloadItems(state.reloadItems, action.payload),
      };
    }
    default:
      return state;
  }
};

export default ParamsReducer;
