import { useState, useContext, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { FilterOptionsState } from "@mui/material";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";

import { useFilterDropdown } from "../../FilterDropdown/hooks";
import FilterListBox from "../../FilterDropdown/FilterListBox";
import { ExtendedFilterDropdownProps } from "../../types/interfaces";

import Autocomplete from "../../../DesignComponents/Autocomplete";

import { DROPDOWN_SIZES, FILTER_NAMES, Filter } from "../../../../shared";
import ParamsContext from "../../../../context/params/paramsContext";

const ExtendedFilterDropdown = ({
  code,
  groupName,
  optionCodes,
  ...props
}: ExtendedFilterDropdownProps &
  Omit<
    MuiAutocompleteProps<any, boolean, boolean, boolean>,
    "renderInput" | "size" | "options"
  >) => {
  const { filteredValues: mainFilteredValues, setQueryParams } =
    useContext(ParamsContext);

  const { isLoading } = useFilterDropdown(groupName);

  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState<Filter[]>([]);

  // Set value
  useEffect(() => {
    const actualFilter = mainFilteredValues.find(
      (filteredValue) =>
        filteredValue.filterName === FILTER_NAMES.ExtendedFilter
    );

    setValue(actualFilter ? actualFilter.value : []);
  }, [mainFilteredValues]);

  const handleChange = (newValue: Filter[]) => {
    const paramsPayload = {
      [FILTER_NAMES.ExtendedFilter]: newValue.map((singleValue) =>
        String(singleValue.id)
      ),
    };

    const filteredValuesPayload = {
      filterName: FILTER_NAMES.ExtendedFilter,
      value: newValue,
    };

    setQueryParams(paramsPayload, true, filteredValuesPayload);
  };

  const getOptionLabel = (option: Filter) => {
    const name = String(option.name);

    return name;
  };

  const loadingIcon = isLoading ? <CircularProgress size={16} /> : null;

  const getOptionDisabled = (option: any) => {
    return option.codeGroup !== Number(code);
  };

  const filterOptions = (
    options: Filter[],
    state: FilterOptionsState<Filter>
  ) => {
    const { inputValue } = state;

    if (inputValue) {
      return options.filter((option: Filter) => {
        return (
          !getOptionDisabled(option) &&
          option.name.toLowerCase().includes(inputValue.toLowerCase())
        );
      });
    } else {
      return options.filter((option: Filter) => !getOptionDisabled(option));
    }
  };

  return (
    <Autocomplete
      {...props}
      disableCloseOnSelect
      clearOnBlur
      fullWidth
      multiple
      size={DROPDOWN_SIZES.Small}
      includeInputInList
      disableClearable
      open={isOpen}
      value={value}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={optionCodes}
      getOptionDisabled={getOptionDisabled}
      filterOptions={filterOptions}
      onChange={(_, newFilterValue) => handleChange(newFilterValue)}
      iconRight={loadingIcon}
      ListboxComponent={FilterListBox}
      renderTags={() => null}
      textFieldParams={{
        placeholder: groupName,
      }}
      getOptionLabel={(option: Filter) => getOptionLabel(option)}
    />
  );
};

export default ExtendedFilterDropdown;
