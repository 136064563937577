import {
  SET_TABLE_DATA,
  SET_TABLE_LOADING,
  DESELECT_ALL_ROWS,
  SET_DETAILS_DATA,
} from "./tableActions";

import { Column, ID_TYPE, TABLE_NAMES } from "../../shared";

export interface TableInitialState {
  tableName: TABLE_NAMES;
  idType: ID_TYPE;
  isNotSelectable: boolean;
  hideSearch: boolean;
  hideFilters: boolean;
  customResource: string;
  sortFromNewest: boolean;
  hasDetails: boolean;
  columns: Column[];
  rows: any[];
  selectedRows: number[];
  detailsData: any;
  currentPage: number;
  rowsPerPage: number;
  totalRows: number;
  isInitialLoading: boolean;
  isTableLoading: boolean;
  fuelType: any;
}

type Action =
  | {
      type: typeof SET_TABLE_DATA;
      payload: any;
    }
  | {
      type: typeof SET_TABLE_LOADING;
      payload: boolean;
    }
  | {
      type: typeof DESELECT_ALL_ROWS;
    }
  | {
      type: typeof SET_DETAILS_DATA;
      payload: {
        detailsData: any;
      };
    };

const TableReducer = (
  state: TableInitialState,
  action: Action
): TableInitialState => {
  switch (action.type) {
    case SET_TABLE_DATA: {
      const { fuelType } = action.payload;

      return {
        ...state,
        ...action.payload,
        fuelType,
        isInitialLoading: false,
        isTableLoading: false,
      };
    }

    case SET_TABLE_LOADING: {
      return {
        ...state,
        isTableLoading: action.payload,
      };
    }

    case DESELECT_ALL_ROWS: {
      return {
        ...state,
        selectedRows: [],
      };
    }
    case SET_DETAILS_DATA: {
      const { detailsData } = action.payload;

      return {
        ...state,
        detailsData,
      };
    }

    default:
      return state;
  }
};

export default TableReducer;
