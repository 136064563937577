import { useContext } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { FilterButtonProps } from "../types/interfaces";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../DesignComponents/Button";

import TableContext from "../../../context/table/tableContext";
import RectangleLoader from "../../../shared/loaders/RectangleLoader";

const FilterButton = ({
  children,
  onOpen,
  hasLoadingCustomStyle,
}: FilterButtonProps) => {
  const { isInitialLoading } = useContext(TableContext);

  const getLeftMargin = () => {
    return hasLoadingCustomStyle ? "0" : "16px";
  };

  return isInitialLoading ? (
    <RectangleLoader
      width={136}
      height={36}
      customStyle={{
        marginLeft: getLeftMargin(),
      }}
    />
  ) : (
    <Button
      color={BUTTON_COLORS.White}
      size={BUTTON_SIZES.Small}
      variant={BUTTON_VARIANTS.IconLeft}
      icon={<FilterAltOutlinedIcon />}
      onClick={onOpen}
    >
      {children}
    </Button>
  );
};

export default FilterButton;
