/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../DesignComponents/Button";

import ParamsContext from "../../context/params/paramsContext";

import NoResultsFoundIcon from "../../assets/customIcons/emptyTable/NoResultsFoundIcon";

const NoResultsFound = () => {
  const { resetAllParams } = useContext(ParamsContext);

  return (
    <Fragment>
      <div>
        <NoResultsFoundIcon
          css={css({
            fontSize: "120px",
          })}
        />
      </div>

      <div>{"No results found"}</div>

      <div>{"Try a different keyword or filter, for more results"}</div>

      <Button
        color={BUTTON_COLORS.Primary}
        size={BUTTON_SIZES.Small}
        variant={BUTTON_VARIANTS.TextOnly}
        onClick={resetAllParams}
      >
        {"Reset view"}
      </Button>
    </Fragment>
  );
};

export default NoResultsFound;
