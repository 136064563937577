import { Fragment, useEffect, useContext } from "react";

import { BrowserRouter as Router } from "react-router-dom";

import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

import useMuiStyles from "./useMuiStyles";
import GlobalStyles from "./GlobalStyles";
import RouterContent from "./RouterContent";

import {
  NOT_FILTER_NAMES,
  PER_PAGE,
  useUrlQueryParams,
  useWindowLocation,
} from "../../shared";
import ParamsContext from "../../context/params/paramsContext";

const Routes = () => {
  const { reloadItems, setQueryParams, setResource } =
    useContext(ParamsContext);

  const { getUrlParams } = useUrlQueryParams();
  const { pathname, resource } = useWindowLocation();

  const muiThemeStyles = useMuiStyles();

  const theme = createTheme(muiThemeStyles);

  useEffect(() => {
    if (pathname !== "/") {
      const queryParamsFromUrl = getUrlParams();

      Object.entries(queryParamsFromUrl).forEach(([id, value]) => {
        if (id === NOT_FILTER_NAMES.PerPage) {
          const correctValues: string[] = Object.values(PER_PAGE);

          if (
            value === null ||
            Array.isArray(value) ||
            !correctValues.includes(value)
          ) {
            queryParamsFromUrl[id] = "";
          }
        }

        value === null && delete queryParamsFromUrl[id];
      });

      const isResourceValid = Object.keys(reloadItems).includes(resource);

      if (isResourceValid) {
        setQueryParams({
          ...queryParamsFromUrl,
        });

        setResource(resource);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <GlobalStyles />

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <RouterContent />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </Fragment>
  );
};

export default Routes;
