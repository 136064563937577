/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
interface MainContainerProps {
  children: React.ReactNode;
}

const MainContainer = ({ children, ...props }: MainContainerProps) => {
  return (
    <main
      {...props}
      className="remaining-height"
      css={css({
        transition: "margin-right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
      })}
    >
      {children}
    </main>
  );
};

export default MainContainer;
