/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import MuiAutocomplete, {
    AutocompleteProps as MuiAutocompleteProps
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/ErrorRounded";

import { AutocompleteProps } from "./types";

import { TOOLTIP_PLACEMENT, TooltipLarge } from "../Tooltips";
import Label from "../Label";

import ThemeContext from "../../../context/theme/themeContext";
import { muiOutlinedInputDefaultStylesOverrides } from "../../../shared";

const Autocomplete = ({
    size,
    labelLeft,
    labelIcon,
    labelRight,
    iconLeft,
    iconRight,
    errorText,
    isRequired,
    width,
    tooltipProps,
    textFieldParams = {},
    customStyle,
    ...props
}: AutocompleteProps &
    Omit<
        MuiAutocompleteProps<any, boolean, boolean, boolean>,
        "renderInput" | "size"
    >) => {
    const {
        colorsFacelift: {
            white,
            blue100,
            blueGray700,
            blueGray500,
            blueGray400,
            blueGray300,
            blueGray200,
            blueGray100,
            textDark,
            textPlaceholder,
            red800,
            red700,
            blue700,
            red100,
            textDarkDisabled
        }
    } = useContext(ThemeContext);

    const paddings = {
        tiny: "2px 12px",
        small: "8px 12px",
        medium: "12px"
    };

    const padding = paddings[size];

    const getWidth = () => {
        if (props.fullWidth) {
            return "100%";
        }

        return width || "unset";
    };

    const containerWidth = getWidth();

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                width: containerWidth,
                ...customStyle
            })}
        >
            {(labelLeft || labelRight) && (
                <Label
                    titleLeft={labelLeft}
                    titleRight={labelRight}
                    titleTooltip={labelIcon}
                    isRequired={isRequired}
                />
            )}

            <MuiAutocomplete
                {...props}
                options={props.options}
                autoComplete
                autoHighlight
                popupIcon={
                    <KeyboardArrowDownRoundedIcon
                        sx={{
                            color: blueGray500
                        }}
                    />
                }
                renderInput={(params: any) => (
                    <TooltipLarge
                        {...tooltipProps}
                        title={tooltipProps?.title || ""}
                        placement={TOOLTIP_PLACEMENT.Top}
                        customMargin="0 0 6px 0"
                    >
                        <TextField
                            {...params}
                            {...textFieldParams}
                            error={Boolean(errorText)}
                            onKeyDown={e => {
                                if (e.key === "Backspace") {
                                    e.stopPropagation();
                                }
                            }}
                            helperText={
                                errorText ? (
                                    <Fragment>
                                        <ErrorIcon
                                            css={css`
                                                color: ${red700};
                                                font-size: 16px;
                                                margin-right: 4px;
                                            `}
                                        />
                                        {errorText}
                                    </Fragment>
                                ) : (
                                    ""
                                )
                            }
                            InputProps={{
                                ...params.InputProps,
                                spellCheck: "false",
                                ...(iconLeft && {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {iconLeft}
                                        </InputAdornment>
                                    )
                                }),
                                ...(iconRight && {
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {iconRight}
                                            {params.InputProps.endAdornment}
                                        </InputAdornment>
                                    )
                                })
                            }}
                            sx={{
                                "& .MuiTouchRipple-root": {
                                    display: "none"
                                },

                                "& .MuiFormHelperText-root": {
                                    margin: "6px 0 0",
                                    display: "flex",
                                    alignItems: "center",
                                    color: blueGray700,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: "600",
                                    letterSpacing: "0.1px",

                                    "&.Mui-error": {
                                        color: blueGray700
                                    }
                                }
                            }}
                        />
                    </TooltipLarge>
                )}
                componentsProps={{
                    paper: {
                        sx: {
                            border: `1px solid ${blueGray200}`,
                            borderRadius: "6px",
                            boxShadow: "0px 2px 8px rgba(15, 23, 42, 0.16)",

                            "& .MuiAutocomplete-listbox": {
                                padding: "0",

                                "& .MuiAutocomplete-option": {
                                    color: textDark,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px",
                                    padding: "14px 16px",
                                    wordBreak: "break-word"
                                },

                                "& .MuiAutocomplete-option.Mui-focused": {
                                    background: blueGray100
                                },

                                '& .MuiAutocomplete-option[aria-selected="true"], .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                                    background: props.multiple
                                        ? white
                                        : blue100,

                                    "&:hover": {
                                        background: props.multiple
                                            ? blueGray100
                                            : blue100
                                    }
                                },

                                '& .MuiAutocomplete-option[aria-disabled="true"]': {
                                    opacity: "1",
                                    color: textDarkDisabled
                                }
                            },

                            "& .MuiAutocomplete-loading, .MuiAutocomplete-noOptions": {
                                color: textPlaceholder,
                                fontSize: "14px",
                                lineHeight: "20px"
                            }
                        }
                    }
                }}
                sx={[
                    {
                        "&:hover, .MuiOutlinedInput-root:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: blueGray400
                            },

                            "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                                borderColor: red800
                            },

                            "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                borderColor: blueGray300
                            }
                        },

                        "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "6px",
                            padding,
                            background:
                                props.disabled || props.readOnly
                                    ? blueGray100
                                    : white
                        },

                        "& .MuiOutlinedInput-root": {
                            "& .MuiAutocomplete-input": {
                                color: textDark,
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                padding: "0"
                            },

                            "& .MuiAutocomplete-endAdornment": {
                                right: "12px",

                                "& > button": {
                                    backgroundColor: "unset"
                                },

                                "& .MuiAutocomplete-popupIndicator": {
                                    marginRight: "0"
                                }
                            }
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: blueGray300
                        }
                    },
                    muiOutlinedInputDefaultStylesOverrides({
                        blue700,
                        blue100,
                        red700,
                        red100,
                        blueGray300,
                        textDarkDisabled,
                        textPlaceholder
                    })
                ]}
            />
        </div>
    );
};

export * from "./types";
export default Autocomplete;
