import { createContext } from "react";
import { ParamsInitialState } from "./paramsReducer";
import {
  FilterDropdownOptions,
  FilteredValue,
  QueryParams,
  TABLE_NAMES,
} from "../../shared";

interface ParamsContextState extends ParamsInitialState {
  setResource: (resource: TABLE_NAMES) => void;
  resetAllParams: () => void;
  setQueryParams: (
    params: QueryParams,
    resetPage?: boolean,
    filteredValues?: FilteredValue,
    resetParams?: boolean,
    removeFilterOptions?: boolean,
    removeSelected?: boolean
  ) => void;
  setFilteredValues: (
    data: FilteredValue[],
    filterDropdownOptions?: FilterDropdownOptions
  ) => void;

  setReloadItems: (resource: TABLE_NAMES | TABLE_NAMES[]) => void;
}

const ParamsContext = createContext({} as ParamsContextState);

export default ParamsContext;
