import { FuelTypeEnumProps } from "../types/interfaces";
import {
  Filter,
  FilteredValue,
  FilterListFieldNames,
  FILTER_NAMES,
  isCheckboxFilter,
  TABLE_NAMES,
  TABLE_NAME_RESOURCES_FOR_FILTERS,
} from "../../../shared";

export const getFilteredListFieldNames = (
  filterNames: FILTER_NAMES[],
  filterListFieldNames: FilterListFieldNames,
  tableName: TABLE_NAMES
) => {
  return filterNames.filter((name) =>
    filterListFieldNames[
      tableName as unknown as TABLE_NAME_RESOURCES_FOR_FILTERS
    ]?.includes(name)
  );
};

export const getFilteredCheckboxValues = (filterNames: FILTER_NAMES[]) => {
  const filteredCheckboxNames = filterNames.filter((name) =>
    isCheckboxFilter(name)
  );

  return filteredCheckboxNames.map((name) => ({
    filterName: name,
    value: [{ id: "null", name }],
  })) as FilteredValue[];
};

export const transformedFilteredItems = (passedFilteredItems: Filter[]) =>
  passedFilteredItems.map((filteredItem: Filter) =>
    filteredItem.id === null
      ? ({
          id: "null",
          name: "not assigned",
        } as Filter)
      : filteredItem
  );

export const filteredItems = (
  options: Filter[],
  filterValue: string | string[] | undefined,
  filterName?: FILTER_NAMES
) =>
  options.filter((option) => {
    const nameOrId =
      filterName !== FILTER_NAMES.ModelFilter ? String(option.id) : option.name;

    return Array.isArray(filterValue)
      ? filterValue.includes(nameOrId)
      : nameOrId === String(filterValue);
  });

export const updateQueryValue = (
  currentQueryValues: string | string[],
  id: number | "null",
  modelName?: string | null
) => {
  const checkIfModel = modelName !== null ? modelName : String(id);

  return Array.isArray(currentQueryValues)
    ? currentQueryValues.filter((value) => value !== checkIfModel)
    : "";
};

export const findActualFilters = (
  filteredValues: FilteredValue[],
  filterName: FILTER_NAMES
) =>
  filteredValues.find(
    (filteredValue) => filteredValue.filterName === filterName
  );

export const updateFilterValues = (
  actualFilter: FilteredValue | undefined,
  id: number | "null",
  modelName?: string | null
) => {
  return actualFilter
    ? actualFilter.value.filter((filter) =>
        modelName !== null ? filter.name !== modelName : filter.id !== id
      )
    : [];
};

export const getModifiedFilterName = (filterName: FILTER_NAMES) => {
  return (() => {
    switch (filterName) {
      case FILTER_NAMES.Make:
        return FILTER_NAMES.MakeFilter;
      case FILTER_NAMES.Model:
        return FILTER_NAMES.ModelFilter;
      case FILTER_NAMES.MakeFilter:
        return FILTER_NAMES.Make;
      case FILTER_NAMES.ModelFilter:
        return FILTER_NAMES.Model;
      default:
        return filterName;
    }
  })();
};

export const getStartYearOptions = (startYearArray: number[]) => {
  return startYearArray
    ? startYearArray.map((year) => ({ id: year, name: String(year) }))
    : [];
};

export const convertEnumToObjectArray = (enumObj: FuelTypeEnumProps) => {
  const enumArray = [];

  for (const key in enumObj) {
    if (enumObj.hasOwnProperty(key)) {
      enumArray.push({ id: parseInt(key), name: enumObj[key] });
    }
  }

  return enumArray;
};
