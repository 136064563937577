/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { useTableData } from "./hooks";
import TableDesktop from "./components/TableDesktop";
import TableFooter from "./components/TableFooter/TableFooter";

import TableToolbar from "../Toolbar";
import TableLoader from "../Loaders/TableLoader";
import TableEmpty from "../TableEmpty";

import TableContext from "../../context/table/tableContext";

const Table = () => {
  const { rows, isTableLoading } = useContext(TableContext);

  useTableData();

  const renderTableData = () => {
    if (rows.length === 0) {
      return isTableLoading ? <TableLoader /> : <TableEmpty />;
    }

    return (
      <div
        className="remaining-height"
        css={css({
          position: "relative",
          overflow: "hidden",
        })}
      >
        {isTableLoading && (
          <div
            css={css({
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "150px",
              width: "100%",
              zIndex: 4,
            })}
          >
            <CircularProgress />
          </div>
        )}

        <div
          css={css({
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            overflow: "auto",
            scrollbarWidth: "thin",
            "&:active": {
              pointerEvents: isTableLoading ? "none" : "auto",
            },
          })}
        >
          <TableDesktop />
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="remaining-height"
        css={css({
          width: `calc(100% - 520px)`,
        })}
      >
        <TableToolbar />
        <div className="remaining-height" data-testid="table">
          {renderTableData()}
          <TableFooter />
        </div>
      </div>
    </>
  );
};

export default Table;
