/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import Menu from "../../DesignComponents/Menu";

import { MenuAnchorEl } from "../../../shared";
import ThemeContext from "../../../context/theme/themeContext";

interface FilterMenuProps {
  isOpen: boolean;
  anchorEl: MenuAnchorEl;
  children: React.ReactNode;
  onClose: () => void;
}

const FilterMenu = ({
  isOpen,
  anchorEl,
  children,
  onClose,
}: FilterMenuProps) => {
  const {
    colorsFacelift: { blueGray700 },
  } = useContext(ThemeContext);

  return (
    <Menu
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      MenuListProps={{ variant: "menu" }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      css={css({
        "& .MuiMenu-paper": {
          width: "328px",
          height: "340px",
        },
      })}
    >
      <div
        css={css({
          padding: "16px",
        })}
      >
        <div
          css={css({
            color: blueGray700,
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            marginBottom: "16px",
          })}
        >
          Table filters
        </div>

        {children}
      </div>
    </Menu>
  );
};

export default FilterMenu;
