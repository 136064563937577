import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import { BUTTON_ACTIONS } from "./types/enums";
import { PaginationProps } from "./types/interfaces";
import Stepper from "./Stepper";
import PaginationLoader from "../../Loaders/PaginationLoader";

const Pagination = ({
  page,
  total,
  perPage,
  onChange,
  isInitialLoading,
}: PaginationProps) => {
  const totalPages = Math.ceil(total / perPage);

  const handleCustomNumber = (input: number) => {
    onChange(input);
  };

  const handleClicks = (buttonName: BUTTON_ACTIONS) => {
    switch (buttonName) {
      case BUTTON_ACTIONS.PreviousPage:
        onChange(page - 1);
        break;

      case BUTTON_ACTIONS.NextPage:
        onChange(page + 1);
        break;

      case BUTTON_ACTIONS.LastPage:
        onChange(totalPages);
        break;

      default:
        onChange(1);
        break;
    }
  };

  return isInitialLoading ? (
    <PaginationLoader />
  ) : (
    <Stepper
      iconFirst={<FirstPageIcon />}
      iconLast={<LastPageIcon />}
      iconLeft={<ChevronLeftRoundedIcon />}
      iconRight={<ChevronRightRoundedIcon />}
      value={page}
      minValue={1}
      maxValue={totalPages}
      tooltipLeft={BUTTON_ACTIONS.PreviousPage}
      tooltipRight={BUTTON_ACTIONS.NextPage}
      tooltipFirst={BUTTON_ACTIONS.FirstPage}
      tooltipLast={BUTTON_ACTIONS.LastPage}
      buttonClick={(buttonName) => handleClicks(buttonName)}
      customValue={(data) => handleCustomNumber(data)}
    />
  );
};

export default Pagination;
