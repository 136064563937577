import { ENCODED_FILTERS } from "../types";

export const useDecoder = () => {
  const decodeStrings = (stringParams: string, queryExists: boolean) => {
    if (queryExists && stringParams) {
      const pairs = stringParams.split("&");
      const result = pairs.map((pair) => {
        const [key, value] = pair.split("=");
        const test: string[] = value.split(",");

        const requiredToEncode: string[] = Object.values(ENCODED_FILTERS);

        const quotedAndSeperated = `${test.join('","')}`;
        const trimmedValue = quotedAndSeperated.trim();

        if (requiredToEncode.includes(key)) {
          return `${key}="${decodeURIComponent(trimmedValue)}"`;
        }
        return `${key}=${value}`;
      });

      return result.join("&");
    } else {
      return stringParams;
    }
  };

  const decodeArray = (arr: string[]): string[] => {
    return arr.map((element) => {
      return decodeURIComponent(element);
    });
  };

  const getDecodedParams = (currentParams: any) => {
    for (const key in currentParams) {
      if (Array.isArray(currentParams[key])) {
        const arr = currentParams[key] as string[];
        const updatedArray = decodeArray(arr);
        currentParams[key] = updatedArray;
      } else {
        currentParams[key] = decodeURIComponent(currentParams[key]);
      }
    }

    return currentParams;
  };

  return { decodeStrings, getDecodedParams, decodeArray };
};
