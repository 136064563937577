import CheckIcon from "@mui/icons-material/Check";
import ExtendedIcon from "./Icons/ExtendedIcon";

interface SupportLevelProps {
  supportLevel: number;
}

const SupportStatus = ({ supportLevel }: SupportLevelProps) => {
  return supportLevel !== 0 ? <ExtendedIcon /> : <CheckIcon />;
};

export default SupportStatus;
