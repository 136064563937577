/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { useFilteredValues } from "../hooks";

import Tag, { TAG_COLORS, TAG_SIZES } from "../../DesignComponents/Tag";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../DesignComponents/Button";
import FilteredValuesLoader from "../../Loaders/FilteredValuesLoader";

import ThemeContext from "../../../context/theme/themeContext";
import { isCheckboxFilter } from "../../../shared";

const FilteredValues = () => {
  const {
    colorsFacelift: { blueGray200, textDark },
  } = useContext(ThemeContext);

  const { isLoading, filteredValues, handleDelete, handleClearAll } =
    useFilteredValues();

  const renderContent = () => {
    if (isLoading) {
      return <FilteredValuesLoader />;
    }

    const getTitle = (name: string) => {
      return isCheckboxFilter(name) ? name.toUpperCase() : name;
    };

    return filteredValues.length > 0 ? (
      <div
        css={css({
          display: "flex",
          alignItems: "center",
          borderTop: `1px solid ${blueGray200}`,
          padding: "8px 24px 8px 24px",
          width: `calc(100% - 48px)`,
        })}
      >
        <div
          css={css({
            color: textDark,
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "36px",
            letterSpacing: "0.1px",
            alignSelf: "flex-start",
            whiteSpace: "nowrap",
            marginRight: "16px",
          })}
        >
          Filtered by:
        </div>

        <div
          css={css({
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            columnGap: "6px",
            rowGap: "6px",
          })}
        >
          {filteredValues.map(({ filterName, value }) => {
            return value.map(({ id, name }) => {
              return (
                <Tag
                  key={`${id}_${name}`}
                  size={TAG_SIZES.Medium}
                  color={TAG_COLORS.Primary}
                  title={getTitle(name)}
                  onDelete={() => handleDelete(filterName, id, name)}
                />
              );
            });
          })}

          <Button
            color={BUTTON_COLORS.White}
            size={BUTTON_SIZES.Small}
            variant={BUTTON_VARIANTS.TextOnly}
            onClick={handleClearAll}
          >
            Clear all
          </Button>
        </div>
      </div>
    ) : null;
  };

  return renderContent();
};

export default FilteredValues;
