/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext } from "react";

import SupportStatus from "../SupportStatus";
import { CODE_GROUP } from "../SupportStatus/types/enums";

import ThemeContext from "../../../context/theme/themeContext";

interface VehicleInfoProps {
  data: any;
  index: number;
  isFirst: boolean;
  isLast: boolean;
}

const VehicleInfo = ({ data, index, isFirst, isLast }: VehicleInfoProps) => {
  const {
    colorsFacelift: { blueGray200, textDarkSecondary, textDarkDisabled },
  } = useContext(ThemeContext);

  const title = CODE_GROUP[data[0].codeGroup];

  return (
    <Accordion
      elevation={0}
      disableGutters
      defaultExpanded={index < 3}
      css={css({
        border: `1px ${blueGray200} solid`,

        boxShadow: "none",

        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent",
        },
        borderRadius: `${isFirst ? "8px 8px 0 0 !important" : ""}${
          isLast ? "0 0 8px 8px !important" : ""
        }`,
      })}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          css={css({
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "0.15px",
            fontWeight: 700,
          })}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.map((entry: any) => {
          return (
            <div
              key={`${entry.name}_${title}`}
              css={css({
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "8px",
              })}
            >
              <Typography
                css={css({
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontWeight: 600,
                  color:
                    entry.SupportLevel !== 1
                      ? textDarkSecondary
                      : textDarkDisabled,
                  width: "315px",
                })}
              >
                {entry.name}
              </Typography>

              <SupportStatus supportLevel={entry.supportLevel} />
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default VehicleInfo;
