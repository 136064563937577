/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { IconLeftButtonProps } from "./types";

const IconLeftButton = ({ title, icon }: IconLeftButtonProps) => {
  return (
    <Fragment>
      <span
        css={css({
          display: "flex",
          alignItems: "center",
          marginRight: "8px",
        })}
      >
        {icon}
      </span>

      {title}
    </Fragment>
  );
};

export default IconLeftButton;
