/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

import { useFilters } from "./hooks";
import FilterDropdown from "./FilterDropdown";
import FilterButton from "./components/FilterButton";
import FilterMenu from "./components/FilterMenu";
import FilterCheckbox from "./components/FilterCheckbox";
import ExtendedFilters from "./ExtendedFilters";

import {
  CODE_TYPE,
  ENDPOINTS,
  FILTER_NAMES,
  filterDropdownCustomMargin,
  filterDropdownCustomWidth,
  useApi,
  vehicleDropdownStyle,
} from "../../shared";

const Filters = () => {
  const { anchorEl, handleOpenMenu, handleCloseMenu } = useFilters();
  const { getData } = useApi();

  const [isLoading, setLoading] = useState(true);
  const [extendedFiltersData, setextendedFiltersData] = useState({
    codeGroup: null,
    codeType: null,
    codes: [],
    startYear: [],
  });

  const getExtendedFiltersData = async () => {
    try {
      setLoading(true);

      const { data } = await getData(ENDPOINTS.Codes);

      const { codeGroup, codeType, codes, startYear } = data;

      setextendedFiltersData({
        codeGroup,
        codeType,
        codes,
        startYear,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getExtendedFiltersData();
    // eslint-disable-next-line
  }, []);

  return (
    <div css={css(vehicleDropdownStyle)}>
      <FilterDropdown
        filter={FILTER_NAMES.Make}
        customFilterName={FILTER_NAMES.MakeFilter}
        placeholder="Make"
        css={css([filterDropdownCustomWidth, filterDropdownCustomMargin])}
      />

      <FilterDropdown
        filter={FILTER_NAMES.Model}
        customFilterName={FILTER_NAMES.ModelFilter}
        placeholder="Model"
        css={css([filterDropdownCustomWidth, filterDropdownCustomMargin])}
      />

      <FilterButton onOpen={handleOpenMenu} hasLoadingCustomStyle>
        More filters
      </FilterButton>

      <FilterMenu
        isOpen={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        <div
          css={css({
            display: "flex",
            div: {
              width: "50%",
            },
          })}
        >
          <FilterCheckbox filter={FILTER_NAMES.Can} title={CODE_TYPE.CAN} />

          <FilterCheckbox filter={FILTER_NAMES.Obd} title={CODE_TYPE.OBD} />
        </div>

        <ExtendedFilters data={extendedFiltersData} isLoading={isLoading} />
      </FilterMenu>
    </div>
  );
};

export default Filters;
