import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ObdIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect width="24" height="24" rx="4" fill="currentColor" />
      <path
        d="M5.28957 9.20125C5.16401 8.51068 5.69453 7.875 6.39643 7.875H17.6036C18.3055 7.875 18.836 8.51068 18.7104 9.20125L17.4377 16.2012C17.3404 16.7362 16.8745 17.125 16.3308 17.125H7.66915C7.12546 17.125 6.65956 16.7362 6.5623 16.2012L5.28957 9.20125Z"
        stroke="#697D95"
        strokeWidth="1.75"
      />
      <circle cx="12" cy="11" r="1" fill="#697D95" />
      <circle cx="15" cy="11" r="1" fill="#697D95" />
      <circle cx="9" cy="11" r="1" fill="#697D95" />
      <circle cx="12" cy="14" r="1" fill="#697D95" />
      <circle cx="15" cy="14" r="1" fill="#697D95" />
      <circle cx="9" cy="14" r="1" fill="#697D95" />
    </SvgIcon>
  );
};

export default ObdIcon;
