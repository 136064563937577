/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import MuiTableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { useSorting } from "../../../hooks";
import TableSortLabel from "../../../../DesignComponents/TableSortLabel";
import ParamsContext from "../../../../../context/params/paramsContext";
import TableContext from "../../../../../context/table/tableContext";
import { Column, ORDERS } from "../../../../../shared";

const TableHead = () => {
  const { queryParams, setQueryParams } = useContext(ParamsContext);

  const { columns } = useContext(TableContext);

  const { order, sort, sortColumn } = useSorting(queryParams, setQueryParams);

  return (
    <MuiTableHead>
      <TableRow>
        {/* Column names */}
        {columns.map(({ name, sortResource, isActive, isSticky }: Column) => {
          const isActiveSorting = sort === sortResource;
          const sortDirection = isActiveSorting ? order : ORDERS.Descending;

          const setLeftPosition = () => {
            if (isSticky) {
              return "0";
            }

            return "unset";
          };

          return (
            isActive && (
              <TableCell
                key={name}
                css={css([
                  {
                    left: setLeftPosition(),
                    zIndex: isSticky ? "3" : "2",
                  },
                ])}
              >
                {sortResource ? (
                  <TableSortLabel
                    isActive={isActiveSorting}
                    direction={sortDirection}
                    onClick={() => sortColumn(sortResource)}
                  >
                    {name}
                  </TableSortLabel>
                ) : (
                  name.charAt(0).toUpperCase() + name.slice(1)
                )}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
