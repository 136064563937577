import { createContext } from "react";

import { TableInitialState } from "./tableReducer";
interface TableContextState extends TableInitialState {
  setTableData: (data: any) => void;
  setTableLoading: (value: boolean) => void;
  deselectAllRows: () => void;
  setDetailsData: (detailsData: any) => void;
}

const TableContext = createContext({} as TableContextState);

export default TableContext;
