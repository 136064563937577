/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import ExtendedFilterDropdown from "./ExtendedFilterDropdown";
import QueryAutocomplete from "./QueryAutocomplete";

import { ExtendedFiltersProps } from "../types/interfaces";
import { convertEnumToObjectArray, getStartYearOptions } from "../functions";

import { CUSTOM_FILTER_NAMES, FILTER_NAMES } from "../../../shared";
import TableContext from "../../../context/table/tableContext";

const ExtendedFilters = ({ data, isLoading }: ExtendedFiltersProps) => {
  const { fuelType } = useContext(TableContext);

  const startYearOptions = getStartYearOptions(data?.startYear);
  const fuelTypeOptions = convertEnumToObjectArray(fuelType);

  return (
    <>
      {!isLoading ? (
        <div
          css={css({
            "> div": {
              marginTop: "12px",
            },
          })}
        >
          <QueryAutocomplete
            filterName={FILTER_NAMES.ProductionYear}
            placeholder={CUSTOM_FILTER_NAMES.ProductionYear}
            options={startYearOptions}
          />

          <QueryAutocomplete
            filterName={FILTER_NAMES.FuelType}
            placeholder={CUSTOM_FILTER_NAMES.FuelType}
            options={fuelTypeOptions}
          />

          {Object.entries(data.codeGroup).map(([key, value]) => {
            return (
              <ExtendedFilterDropdown
                key={key}
                code={key}
                groupName={value}
                optionCodes={data.codes}
              />
            );
          })}
        </div>
      ) : (
        <div
          css={css({
            "> div": {
              margin: "12px",
            },
          })} //make a skeleton
        >
          Loading
        </div>
      )}
    </>
  );
};

export default ExtendedFilters;
